
import { Col, Modal, Row } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";
import { Editor } from '@tinymce/tinymce-react';
import Select from "react-select";
import notify from "../../helpers/Notify";
import { useRecoilValue } from "recoil";
import { themeTypeState } from "../../../state/GlobalState";
import { getRefByCNRequest } from "../../../api/controller/GuestController";
import { userState } from "../../../api/state/AuthState";

const MceEditor = ({ value={}, onChange=()=>false, simpleMode=false, height=500 , initialSearch="", searchClickIndex=0, disabled=false, readOnly=false, hidden=false}) => {

    const themeType = useRecoilValue(themeTypeState)
    const user = useRecoilValue(userState)

    const editorRef = useRef(null)
    const [editorDisabled, setEditorDisabled] = useState(true)
    const [content, setContent] = useState(typeof(value) === 'string' && value.length > 0 ? value : (value?.Content || ""))
    const [annModalVisible, setAnnModalVisible] = useState(false)
    const [annFormData, setAnnFormData] = useState({})
    const annotationsRef = useRef(value?.Annotations || [])
    const [annotations, _setAnnotations] = useState(value?.Annotations || [])
    const setAnnotations = (data) => {
        _setAnnotations(data)
        annotationsRef.current = data
    }

    useEffect(() => {
        setEditorDisabled(disabled || readOnly)
    }, [disabled, readOnly, setEditorDisabled])

    useEffect(() => {
        if(! editorDisabled){
            if(simpleMode){
                onChange(content)
            } else {
                onChange({ Content: content, Annotations: annotations })
            }
        }
        // eslint-disable-next-line
    }, [content, annotations])

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const options = {
        upload: false,
        uploadFn: async(file) => ({ status: 'error', message: '', url: '' })
    }

    const createSvgCircle = (color) => {
        return `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10" fill="${color}"/></svg>`;
    }
    
    if (hidden) return null;

    const registerReferenceNo = (editor) => {
        editor.ui.registry.addIcon(`reference-no`, '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 28 26.988"> <g id="Layer_x0020_1" transform="translate(-1.27 -8.9)"> <path class="ql-fill" id="Path_5" data-name="Path 5" d="M24,22.479q2.1-.776,2.911-1.1a2.094,2.094,0,0,1,.637-.072.9.9,0,0,1,.3-.048,1.23,1.23,0,0,1,1.042.676,2.222,2.222,0,0,1,.374,1.241,1.264,1.264,0,0,1-.716,1.209l-1.678.652c-.58.215-1.439.533-2.593.954-.6.215-.907.39-.907.509a.208.208,0,0,0,.024.1.627.627,0,0,0-.072.143c0,.119.373.509,1.121,1.161a3.15,3.15,0,0,1,1.026,2.052,2.759,2.759,0,0,1-.048,1.169,5.115,5.115,0,0,1-2.076,2.7,7.749,7.749,0,0,1-3.166,1.352,34.358,34.358,0,0,1-5.241.676c-2.331.1-3.65.024-3.961-.207-.7-.295-1.05-1.185-1.05-2.656,0-.763.222-1.177.676-1.233a5.359,5.359,0,0,1,1.582.406,6.845,6.845,0,0,0,1.885.453,15.785,15.785,0,0,0,2.076-.119,15.772,15.772,0,0,0,2.8-.509c1.487-.342,2.235-.923,2.235-1.749a6.442,6.442,0,0,0-1.869-1.415,2.284,2.284,0,0,1-1.281-1.98q0-2.172,5.965-4.359ZM2.955,24.112l.971-3.505H1.27l.2-1.861H4.375L5.5,14.293H1.526l.2-1.834H6L6.928,8.9H9.171l-.93,3.559H13.48L14.5,8.9h2.243l-.989,3.559H18.4l-.2,1.834H15.322l-1.143,4.453h3.961l-.2,1.861H13.713l-.975,3.505H10.526l.944-3.505H6.2l-1,3.505Zm3.678-5.367H11.9l1.17-4.453h-5.3L6.634,18.745Z" fill="#373435"></path> </g> </svg>')
        editor.ui.registry.addButton('reference-no', {
            icon: 'reference-no',
            onAction: () => editor.undoManager.transact(() => editor.selection.setContent(`<span class="csref-no">${editor.selection.getContent()}</span>`))
        })
    }

    const removeAdjacentDuplicatesFromContent = (htmlString) => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(htmlString, 'text/html');
        function processTextNode(node) {
            node.textContent = node.textContent.replace(/(.)\1+/g, '$1');
        }
        function processNode(node) {
            if (node.nodeType === Node.TEXT_NODE) {
                processTextNode(node);
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                Array.from(node.childNodes).forEach(processNode);
            }
        }
        processNode(doc.body);
        return doc.body.innerHTML;
    }

    const registerQuickShortcuts = (editor) => {
        
        editor.ui.registry.addMenuButton('quick-shortcuts-dropdown', {
            icon: 'settings',
            fetch: (callback) => callback([
                {
                    type: 'menuitem',
                    text: 'Flip Brackets',
                    onAction: function () {
                        let content = editor.selection.getContent()
                        if(content){
                            content = content.replace(/\(/g, 'xx{xx');
                            content = content.replace(/\)/g, '(');
                            content = content.replace(/\xx{xx/g, ')');
                            editor.undoManager.transact(() => editor.selection.setContent(content))
                        }
                    },
                },
                {
                    type: 'menuitem',
                    text: 'Remove Duplicate Neighbors',
                    onAction: function () {
                        let content = editor.selection.getContent()
                        if(content){
                            editor.undoManager.transact(() => editor.selection.setContent(removeAdjacentDuplicatesFromContent(content)))
                        }
                    },
                },
                {
                    type: 'menuitem',
                    text: 'Diacritic Space Remover',
                    onAction: function () {
                        let content = editor.selection.getContent()
                        if(content){
                            const diacritics = ['ް','ަ','ި','ު','ެ','ާ','ީ','ޫ','ޭ','ޮ','ޯ']
                            diacritics.forEach((d)=>{
                                content = content.replaceAll(" "+d, d)
                            })
                            editor.undoManager.transact(() => editor.selection.setContent(content))
                        }
                    },
                },
                {
                    type: 'menuitem',
                    text: 'Reverse Text',
                    onAction: function () {
                        let content = editor.selection.getContent()
                        if(content){
                            content = content.split('').reverse().join('')
                            editor.undoManager.transact(() => editor.selection.setContent(content))
                        }
                        
                    },
                },
                {
                    type: 'menuitem',
                    text: 'Reverse Text (Line by Line)',
                    onAction: function () {
                        let content = editor.selection.getContent()
                        if(content){
                            content = content.split('<br>').map((line) => line.split('').reverse().join('')).join('<br>')
                            editor.undoManager.transact(() => editor.selection.setContent(content))
                        }
                        
                    },
                },
                {
                    type: 'menuitem',
                    text: 'Join Lines',
                    onAction: function () {
                        let content = editor.selection.getContent()
                        if(content){
                            content = content.replaceAll('<br>', ' ')
                            editor.undoManager.transact(() => editor.selection.setContent(content))
                        }
                        
                    },
                },
            ])
        });
    }

    const registerFootnotes = (editor) => {
        editor.ui.registry.addButton('footnote', {
            icon: 'footnote',
            onAction: () => {
                if(editor.selection.getNode().closest('.mce-footnotes')) return;
                const uniqueId = new Date().getTime();
                editor.insertContent(`<sup id="footnote_${uniqueId}" class="text-footnote"><a href="#footnotes_entry_${uniqueId}" contenteditable="false">&ZeroWidthSpace;</a></sup> `);
                const position = Array.from(editor.dom.select('.text-footnote')).findIndex(element => element.id === `footnote_${uniqueId}`) + 1;
                let container = editor.dom.select('.mce-footnotes ol')[0];
                if (!container) editor.dom.add(editor.getBody(), 'div', { 'class': 'mce-footnotes', contenteditable: "false" }, '<hr><ol></ol>');
                container = editor.dom.select('.mce-footnotes ol')[0];
                const liElement = editor.dom.create('li', { id: `footnotes_entry_${uniqueId}` }, `<a class="mce-footnotes-backlink" href="#footnote_${uniqueId}">^&nbsp;</a><span class="mce-footnotes-note" contenteditable="true"></span>`);
                if (position !== -1 && container.childNodes[position-1]) {
                    container.insertBefore(liElement, container.childNodes[position-1]);
                } else {
                    container.appendChild(liElement);
                }
                const insertedItem = editor.dom.select(`#footnotes_entry_${uniqueId}`)[0]?.querySelector('.mce-footnotes-note');
                if(insertedItem){
                    const rng = document.createRange();
                    rng.setStart(insertedItem, 0);
                    rng.setEnd(insertedItem, 0);
                    editor.selection.setRng(rng);
                    // insertedItem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                }
            },
            onSetup: (buttonApi) => {
                const updateState = () => {
                    const isFootnote = editor.selection.getNode().closest('.mce-footnotes');
                    buttonApi.setEnabled(!isFootnote);
                };
                editor.on('NodeChange', updateState);
                return () => editor.off('NodeChange', updateState);
            }
        })
        editor.on('NodeChange', (e) => {
            const allFootnotes = editor.dom.select('sup[id^="footnote_"]');
            const allFootnoteEntries = editor.dom.select('li[id^="footnotes_entry_"]');
            const footnoteSet = new Set(allFootnotes.map(e => e.id));
            allFootnoteEntries.forEach((entry) => {
                const entryId = entry.id.replace('footnotes_entry_', 'footnote_');
                if (!footnoteSet.has(entryId)) {
                    editor.dom.remove(entry);
                }
            });
            allFootnotes.forEach((footnote) => {
                if(footnote.textContent === ""){
                    editor.dom.remove(footnote);
                }
            });
        })
        editor.on('click', (e) => {
            const target = e.target;
            if (target.closest('li[id^="footnotes_entry_"]')) {
                const span = target.querySelector('.mce-footnotes-note');
                if (span && !span.textContent.trim()) {
                    editor.selection.select(span);
                }
            }
        });
    }

    const registerAnnotations = (editor) => {
        const types = ['General','Positive', 'Neutral', 'Warning', 'Cautionary'];
        const colors = ['rgb(245, 245, 245)','rgb(191, 237, 210)', 'rgb(206, 212, 217)', 'rgb(251, 238, 184)', 'rgb(248, 202, 198)'];
        types.forEach((type, index) => editor.ui.registry.addIcon(`annotate-icon-${type.toLocaleLowerCase()}`, createSvgCircle(colors[index])))
        editor.on('init', () => types.forEach((type, index) => editor.annotator.register(type, { persistent: true, decorate: (uid) => ({ uid: uid, attributes: { 'style': `background-color: ${colors[index]};` }}) })) );
        editor.ui.registry.addMenuButton('annotate-dropdown', {
            icon: 'comment',
            fetch: (callback) => callback(types.map(type => ({
                type: 'menuitem',
                text: type,
                icon: `annotate-icon-${type.toLocaleLowerCase()}`,
                onAction: function () {
                    const selectionLength = editor.selection.getContent().length;
                    if (selectionLength> 0) {
                    setAnnFormData({ Type: type, Title: "", Category: "", Description: "" });
                    setAnnModalVisible(true);
                    }
                },
            })))
        });
        editor.ui.registry.addMenuItem('editannotation', {
            text: 'Edit Annotation',
            icon: 'edit-block',
            onAction: () => {
                const uid = editor.selection.getNode().closest('.mce-annotation')?.getAttribute('data-mce-annotation-uid')
                if(uid){
                    setAnnFormData({...annotationsRef.current.find((item) => String(item.uid) === String(uid))})
                    setAnnModalVisible(true)
                }
            },
            onSetup: (api) => api.setEnabled(editor.selection.getNode().closest('.mce-annotation') ? true : false)
        })
        editor.ui.registry.addMenuItem('deleteannotation', {
            text: 'Delete Annotation',
            icon: 'remove',
            onAction: () => {
                const annotation = editor.selection.getNode().closest('.mce-annotation')
                const uid = annotation?.getAttribute('data-mce-annotation-uid')
                if(annotation && uid){
                    setAnnotations(annotationsRef.current.filter((item) => String(item.uid) !== String(uid)))
                    editor.annotator.remove(annotation?.getAttribute('data-mce-annotation'))
                }
            },
            onSetup: (api) => api.setEnabled(editor.selection.getNode().closest('.mce-annotation') ? true : false)
        })
    }

    const handleImageUpload = (blobInfo) => new Promise(async(resolve, reject) => {
        const file = await toBase64(blobInfo.blob())
        if(options.upload){
            const response = await options.uploadFn({ file:file })
            if(response && response.status === "success"){
                return resolve(response.url)
            } else if(response && response.status === "error"){
                return reject("Error: " + response.message)
            } else {
                return reject("Error: Something went wrong uploading the image")
            }
        } else {
            return resolve(file)
        }
    })
      

    return (
        <div>
            {! editorDisabled ? (
                <>
                    <Editor
                        onInit={(evt, editor) => editorRef.current = editor}
                        tinymceScriptSrc={'tinymce/tinymce.min.js'}
                        init={{
                            height: height,
                            menubar: !simpleMode,
                            plugins: 'advlist autolink lists link image charmap preview anchor ' +
                                'searchreplace visualblocks code fullscreen ' +
                                'insertdatetime media table code help wordcount directionality importcss'
                            ,
                            toolbar: 'fullscreen | redo undo | reference-no footnote | annotate-dropdown quick-shortcuts-dropdown | ' +
                                'strikethrough underline italic bold | subscript superscript | rtl ltr | ' +
                                'alignright aligncenter alignleft alignjustify | bullist numlist indent outdent | ' +
                                'backcolor forecolor | link image media | blocks | removeformat',
                            content_css: '/tinymce/themes/custom.css',
                            font_family_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Faruma=faruma; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
                            directionality: 'rtl',
                            images_upload_handler: handleImageUpload,
                            help_tabs: ['shortcuts', 'keyboardnav'],
                            help_accessibility: false,
                            contextmenu: "link | editannotation | deleteannotation",
                            toolbar_mode: 'sliding',
                            setup: (editor) => {
                                registerReferenceNo(editor)
                                registerQuickShortcuts(editor)
                                if(! simpleMode){
                                    registerFootnotes(editor)
                                    registerAnnotations(editor)
                                }
                            }
                        }}
                        value={content}
                        onEditorChange={(c) => setContent(c)}
                    />
                    
                    <Modal size="lg" isOpen={annModalVisible} toggle={()=>setAnnModalVisible(false)} keyboard={false} backdrop="static">
                        <div className="modal-header">
                            <h5 className="dv modal-title mt-0" id="myModalLabel">ކޮމެންޓް</h5>
                            <button type="button" onClick={() => setAnnModalVisible(false)} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div id="modalBody" className="modal-body">
                            <Row>
                                <Col xs={12} sm={6}>
                                    <label className="form-label">މާއްދާ </label>
                                    <input type="text" className="dv form-control" placeholder="މާއްދާ" value={annFormData?.Title || ""} onChange={(e) => setAnnFormData({...annFormData, Title: e.target.value})} />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <label className="form-label">ކެޓެގަރީ </label>
                                    <Select className="dv select2-selection zIndex999" 
                                            options={[{value: 'އެޑިޓަރުގެ ކޮމެންޓް', label: 'އެޑިޓަރުގެ ކޮމެންޓް'},{value: 'އުސޫލު', label: 'އުސޫލު'}]} 
                                            value={annFormData?.Category ? { label: annFormData?.Category, value: annFormData?.Category } : ''}
                                            onChange={(e)=>setAnnFormData({...annFormData, Category: e.value})} 
                                            placeholder="ނަންގަވާ..." />
                                </Col>
                                <Col xs={12} className="dv">
                                    <label className="form-label mt-4">ތަފްޞީލު </label>
                                    <Editor 
                                        value={annFormData?.Description || ""}
                                        onEditorChange={(e)=>setAnnFormData({...annFormData, Description: e})}
                                        tinymceScriptSrc={'tinymce/tinymce.min.js'}
                                        init={{
                                            height: 300,
                                            menubar: false,
                                            plugins: 'advlist autolink lists link image charmap preview anchor ' +
                                                'searchreplace visualblocks code fullscreen ' +
                                                'insertdatetime media table code help wordcount directionality importcss'
                                            ,
                                            toolbar: 'fullscreen | redo undo | reference-no | ' +
                                                'strikethrough underline italic bold | subscript superscript | rtl ltr | ' +
                                                'alignright aligncenter alignleft alignjustify | bullist numlist indent outdent | ' +
                                                'backcolor forecolor | link image media | blocks | removeformat',
                                            content_css: '/tinymce/themes/custom.css',
                                            font_family_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Faruma=faruma; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
                                            directionality: 'rtl',
                                            help_tabs: ['shortcuts', 'keyboardnav'],
                                            help_accessibility: false,
                                            toolbar_mode: 'wrap',
                                            setup: (editor) => {
                                                registerReferenceNo(editor)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={() => setAnnModalVisible(false)} className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
                            <button type="button" onClick={() => {
                                if(annFormData?.Title === ""){
                                    notify({ status: "error", message: "މާއްދާ ބޭނުންވޭ" })
                                    return;
                                } else if(annFormData?.Category === ""){
                                    notify({ status: "error", message: "ކެޓެގަރީ ބޭނުންވޭ" })
                                    return;
                                } else if(annFormData?.Description === ""){
                                    notify({ status: "error", message: "ތަފްޞީލު ބޭނުންވޭ" })
                                    return;
                                }
                                if(! annFormData.uid){
                                    annFormData.uid = new Date().getTime()
                                    setAnnotations([...annotations, annFormData])
                                } else {
                                    const index = annotations.findIndex((item) => item.uid === annFormData.uid)
                                    const newAnnotations = [...annotations]
                                    newAnnotations[index] = annFormData
                                    setAnnotations(newAnnotations)
                                }
                                editorRef.current?.annotator.annotate(annFormData?.Type, { uid: annFormData?.uid });
                                setAnnModalVisible(false)
                                editorRef.current?.focus();
                            }} className="btn btn-primary waves-effect">Save</button>
                        </div>
                    </Modal>
                </>
            ) : (
                <div style={{position: 'relative'}}>
                    <Editor 
                        // disabled={true}
                        value={content}
                        onInit={(evt, editor) => editorRef.current = editor} 
                        tinymceScriptSrc={'tinymce/tinymce.min.js'}
                        init={{
                            readonly: 1,
                            height: height,
                            menubar: false,
                            plugins: 'fullscreen findonly',
                            toolbar: 'fullscreen findonly',
                            content_css: themeType === 'dark' ? ['dark','/tinymce/themes/custom.css'] : ['/tinymce/themes/custom.css'],
                            directionality: 'rtl',
                            editorDisabled: true,
                            toolbar_mode: 'wrap',
                            statusbar: false,
                            init_instance_callback: (editor) => {
                                if(initialSearch.length > 0){
                                    setTimeout(() => editor.execCommand('FindNow', false, { SearchParam: initialSearch, ClickIndex: searchClickIndex }), 500) 
                                }
                            },
                            setup: (editor) => {
                                editor.on('click', async(e) => {
                                    if (e.target.closest('.mce-annotation')) {
                                        const uid = e.target.closest('.mce-annotation')?.getAttribute('data-mce-annotation-uid')
                                        setAnnModalVisible(true)
                                        setAnnFormData({...annotationsRef.current.find((item) => String(item.uid) === String(uid))})
                                    } else if(e.target.getAttribute('data-mce-href')?.startsWith("#footnotes_entry_")){
                                        editor.dom.select(e.target.getAttribute('data-mce-href'))[0]?.scrollIntoView()
                                    } else if(e.target.getAttribute('data-mce-href')?.startsWith("#footnote_")){
                                        editor.dom.select(e.target.getAttribute('data-mce-href'))[0]?.scrollIntoView()
                                    } else if(e.target.closest('.csref-no')){
                                        const response = await getRefByCNRequest({ CaseNumber: e.target.closest('.csref-no').textContent })
                                        if(response?.Status === "success"){
                                            window.open(`/case?ref=${response?.Reference}`, "_blank")
                                        }
                                    }
                                })
                                editor.on('keydown', (e) => (((!(e.ctrlKey && e.code === "KeyC" && user?.subscription?.copy_access)) && (!(e.key === "F5")))) ? e.preventDefault() : e)
                                editor.on('init', ()=>{
                                    editor.getBody().style.caretColor = 'transparent';
                                    editor.getBody().setAttribute('contenteditable', false);
                                })
                                if(! user?.subscription?.copy_access){
                                    editor.on('mousedown', (e)=> e.preventDefault())
                                    editor.on('contextmenu', (e) => e.preventDefault())
                                    editor.on('dblclick', (e) => e.preventDefault())
                                }
                            }
                        }}
                    />
                    
                    <Modal size="lg" isOpen={annModalVisible} toggle={()=>setAnnModalVisible(false)}>
                        <div className="modal-header">
                            <h5 className="dv modal-title mt-0" id="myModalLabel">ކޮމެންޓް</h5>
                            <button type="button" onClick={() => setAnnModalVisible(false)} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div id="modalBody" className="modal-body">
                            <Row>
                                <Col xs={12} sm={6}>
                                    <p style={{fontSize:'1.2em'}}><strong>މާއްދާ: </strong><span>{annFormData?.Title || ""}</span></p>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <p style={{fontSize:'1.2em'}}><strong>ކެޓެގަރީ: </strong><span>{annFormData?.Category || ""}</span></p>
                                </Col>
                                <hr />
                                <Col xs={12} className="dv">
                                    <label><strong>ތަފްޞީލު</strong></label>
                                    <div className="dv" dangerouslySetInnerHTML={{__html: annFormData?.Description || ""}}></div>
                                </Col>
                            </Row>
                        </div>
                    </Modal>
                </div>
            )}
        </div>
        
    )
    
}

export default MceEditor;

