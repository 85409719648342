import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Col, Row, Button, Label, Form, FormGroup, Input, Card, NavItem, NavLink, Progress, TabContent, TabPane, PopoverHeader, PopoverBody, } from 'reactstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { lastAPIActionTimeState, pageWebsocketsEnabledState, themeDirection } from '../../state/GlobalState';
import { handleResponse } from '../helpers/HandleResponse';
import { Editor } from "react-draft-wysiwyg";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import CreatableSelect from 'react-select/creatable';
import Switch from "react-switch";
import InputMask from "react-input-mask";
import Flatpickr from "react-flatpickr"
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "flatpickr/dist/themes/material_blue.css"
import { cloneDeep, debounce, get, merge } from 'lodash';
import { stateFromHTML } from 'draft-js-import-html'
import { EditorState } from 'draft-js'
import Dropzone from 'react-dropzone';
import { Link, Popover } from '@material-ui/core';
import classnames from 'classnames';
import notify from '../helpers/Notify';
import moment from 'moment';
import {NestedSelect} from "./form-elements/NestedSelect";
import { convertToThaana } from '../helpers/ConvertToThaana';
import { ThaanaInput } from './CustomInput';
import { isDhivehi } from '../helpers/IsDhivehi';
import QuillEditor from './form-elements/QuillEditor';
import MceEditor from './form-elements/MceEditor';
import { TagsInput } from "react-tag-input-component";

export const DynamicForm = ({config, ds, submitModes, setFormSubmitDisabled, disableSockets=false}) => {

    const dir = useRecoilValue(themeDirection)

    const setLastAPIActionTime = useSetRecoilState(lastAPIActionTimeState)
    const pageWebsocketsEnabled = useRecoilValue(pageWebsocketsEnabledState)

    // Get Name to Post with (example: Test Name = TestName or Custom Name if provided)
    const getPN = (val) => {
      return getMTU(val)?.name || val.name || val.label.replace(/ /g, '')
    }
    
    // Get Name of Field (example: Test Name = test_name or Custom Name if provided)
    const getFN = (val) => {
      return getMTU(val)?.field || val.field || val.label.replace(/ /g, '_').toLowerCase()
    }

    // Label Generator for custom labels based on data
    const getLL = (val) => {
      return val.labelFn ? val.labelFn(formData, config.data) : val.label
    }
    
    // Get Nested Elements, within objects like formData, currentFiles, etc... , param1: obj to get data from, param2: input field to refer to
    const getNE = (oe, input) => {
      try{
        return input.repName ? oe[input.repName][input.repKey] ? oe[input.repName][input.repKey][getPN(input)] : null : oe[getPN(input)]
      } catch(e){
        return null;
      }
    }

    const formatSelectRow = (row, column) => (
      row?.constructor === "temp".constructor ? { label: row, value: row } : { label: get(row, column.subFields?.label || "name"), value: get(row, column.subFields?.value || "id") }
    )

    // get Updated Parent Element (updates nested element with value and returns parent element)
    const getUPE = (oe, input, value, property=false) => {
      if(input.repName){
        return {...oe, [input.repName]: oe[input.repName].map((t,k)=>(k === input.repKey ? {...t, [getPN(input)] : property ? {...t[getPN(input)], [property]: value, default: null}: value} : t)) }
      }
      return {...oe, [getPN(input)]: property ? {...oe[getPN(input)], [property]: value, default: null}: value}
    }
    
    const getMTU = useMemo(() => (val) => {
      let temp = val?.[config.type?.toLowerCase()]?.[config.typeKey]
      if(temp instanceof Object){
        return temp
      } else if(temp && temp.startsWith("same_as:")) {
        temp = temp.substr(8).split(".")
        return val[temp[0]][temp[1]]
      }
      return false
    }, [config.type, config.typeKey]);

    // Returns the default type of inputs
    const getDefaultDataTypes = (input) => {
      return input.type === "multiselect" || input.type === "dropzone" || input.type === "repeater" ? [] : (input.type === "checkbox" || input.type === "switch" ? "false" : (input.type === "file" ? null : ''))
    }

    const [isLoading, setIsLoading] = useState({})
    const [formData, setFormData] = useState({})
    const [draftEditors, setDraftEditors] = useState({})
    const [currentFiles, setCurrentFiles] = useState({})
    const [selects, setSelects] = useState({})
    const [selectOptions, setSelectOptions] = useState({})
    const [attributes, setAttributes] = useState({})
    const [nestedSelects, setNestedSelects] = useState({})
    const [wizardConfig, setWizardConfig] = useState({})
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [formLoading, setFormLoading] = useState(true)
    const [confirmDialog, setConfirmDialog] = useState({ id: null, input: null, key: null, top: 0, left: 0, visible: false })

    useEffect(() => {
      setFormSubmitDisabled(submitDisabled)
      // eslint-disable-next-line
    }, [submitDisabled])
    
    const parseAttributes = useMemo(() => (fd, ld, sd) => {
      // pass data structure as first element (In recursive or repeater mode, it will be children), pass form data as second element (for recursion), loading state is passed as 3rd (for recursion) Selects state is passed as 4th (for recursion)
      const getAttributes = (fs, fd, ld, sd) => {
        let tempAttributes = {}
        fs.filter((val)=> getMTU(val)?.enabled).forEach((val) => {
          let currentValidations = {}
          if(getMTU(val).validations){
            let validations = getMTU(val).validations.split("|")
            validations.forEach((validation) => {
              if(validation.includes("_if")){
                // hidden_if:LinkType,!AccountVerification
                const params = validation.split(":")[1]?.split(",")
                validation = validation.split(":")[0].replace("_if","")
                let conditionMet = false
                for(var i=1; i< params.length; i++){
                  const not = params[i].substr(0,1) === '!' ? true : false;
                  params[i] = not ? params[i].substr(1) : params[i]
                  let check = params[i] === 'null' ? '' : ((params[i] === '0' || params[i] === 0) ? false : (params[i] === '1' || params[i] === 1) ? true : params[i])
                  let fdValue = fd[params[0]] === null ? '' : ((fd[params[0]] === '0' || fd[params[0]] === 0) ? false : (fd[params[0]] === '1' || fd[params[0]] === 1) ? true : fd[params[0]])
                  const sdValue = sd[params[0]] === null ? '' : ((sd[params[0]] === '0' || sd[params[0]] === 0) ? false : (sd[params[0]] === '1' || sd[params[0]] === 1) ? true : sd[params[0]])
                  fdValue = sdValue ? sdValue.label : fdValue
                  if(typeof(check) === "string" && (check.match("%"))){
                    const temp = check.replace(/%\s?/g, '');
                    if(fdValue){
                      if(check.startsWith("%") && check.endsWith("%")){
                        conditionMet = (!not && (String(fdValue).match(temp))) || (not && (!String(fdValue).match(temp)))
                      } else if (check.startsWith("%")){
                        conditionMet = (!not && (String(fdValue).endsWith(temp))) || (not && (!String(fdValue).endsWith(temp)))
                      } else if (check.endsWith("%")){
                        conditionMet = (!not && (String(fdValue).startsWith(temp))) || (not && (!String(fdValue).startsWith(temp)))
                      } else {
                        conditionMet = (!not && (String(fdValue).startsWith(temp) && String(fdValue).endsWith(temp))) || (not && (!(String(fdValue).startsWith(temp) && String(fdValue).endsWith(temp))))
                      }
                    } else {
                      conditionMet = not ? true : false
                    }
                    check = temp
                  } else {
                    conditionMet = (!not && (fdValue === check || parseInt(fdValue) === parseInt(check) || String(fdValue) === String(check))) || (not && (fdValue !== check && parseInt(fdValue) !== parseInt(check) && String(fdValue) !== String(check)))
                  }
                  if(conditionMet){
                    break;
                  }
                }
                
                if(conditionMet){
                  validation = validation === "readonly" ? "readOnly" : validation
                  currentValidations = {...currentValidations, [validation]: true}
                  if(validation === "disabled" && (val.type === "select" || val.type === "multiselect")){
                    currentValidations = {...currentValidations, isDisabled: true}
                  }
                }
              } else {
                validation = validation === "readonly" ? "readOnly" : validation
                currentValidations = {...currentValidations, [validation]: true}
                if(validation === "disabled" && (val.type === "select" || val.type === "multiselect")){
                  currentValidations = {...currentValidations, isDisabled: true}
                }
              }
            })
          }
          if(val.type === "repeater" && val.children.length > 0){
            tempAttributes = {...tempAttributes, [getPN(val)+"Repeater"]: currentValidations}
            currentValidations = fd[getPN(val)].map((el,k) => ({...getAttributes(val.children, el, ld[getPN(val)][k],sd[getPN(val)] ? sd[getPN(val)][k] : {})}))
            tempAttributes = {...tempAttributes, [getPN(val)]: currentValidations}
          } else {            
            if(getNE(ld, val) || false){
              currentValidations = {...currentValidations, isDisabled: true, disabled: true }
            } 
            tempAttributes = {...tempAttributes, [getPN(val)]: currentValidations}
          }
          
        })
        return tempAttributes
      }
      return getAttributes(ds, fd, ld, sd)
      // eslint-disable-next-line
    }, [ds, getMTU]);

    const getStateData = useMemo(() => async(columns=ds, data=config.data, updateOrView=config.type==="Update" || config.type==="View") => {
      const getFormData = (cols, data, updateOrView, staticOptions={}) => {
        let tempData = updateOrView ? { id: data.id } : {}
        cols.filter((col)=> getMTU(col)?.enabled).forEach((col) => {
          let def = '';
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? get(data, getFN(col)).map((ir)=>(getFormData(col.children, ir, updateOrView, staticOptions))) : [{...getFormData(col.children, {}, get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0, staticOptions)}]
          } else {
            if(updateOrView){
              if(col.type === "select" || col.type === "multiselect"){
                let temp = ''
                if(col.pivoted){
                  temp = get(data, "id");
                } else {
                  temp = get(data, getFN(col));
                  if(col.rootValue && col.staticOptions){
                    temp = getNE(staticOptions, col).find(el => el.label === temp)?.value || temp
                  }
                }
                if(col.type === "multiselect" && temp?.constructor === "temp".constructor){
                  if(temp.constructor === "temp".constructor && temp !== ""){
                    try{
                    temp = JSON.parse(temp) 
                    } catch(e){ console.log("Error Parsing JSON") }
                  }
                  def = temp
                } else {
                  temp = temp instanceof Array ? temp.map((val)=>(formatSelectRow(val,col))) : get(temp, col.subFields?.label || "name") ? formatSelectRow(temp,col) : (col.rootValue || col.pivoted) ? temp : ''
                  def = temp instanceof Array ? temp.map((val)=>(val.value)) : (col.rootValue || col.pivoted) ? temp : temp.value
                }
                def = config.type === "View" ? (getMTU(col).default || def) : def
              } else if(col.type === "switch"){
                def = parseInt(get(data, getFN(col))) === 1 || get(data, getFN(col)) === true
              } else if(col.type === "file" || col.type === "dropzone"){
                def = getDefaultDataTypes(col)
              } else if(col.type === "radio"){
                def = get(data, getFN(col)) ? get(data, getFN(col))+"" : (config.type === "View" ? (getMTU(col).default || "") : "")
              } else if(col.type === "datepicker" && getMTU(col).dateFormat){
                def = get(data, getFN(col)) ? moment(get(data, getFN(col))).format(getMTU(col).dateFormat) : (config.type === "View" ? (getMTU(col).default || "") : "")
                if(config.type === "View" && get(data, getFN(col)) && col.table?.dateFormat){
                  def = moment(get(data, getFN(col))).format(col.table.dateFormat)
                }
              } else if(col.type === "nestedselect"){
                let temp = get(data, getFN(col));
                def = get(temp, col.subFields?.label || "name") ? get(temp, col.subFields?.value || "id") : col.rootValue ? temp : ''
              } else {
                def = get(data, getFN(col)) === 0 ? "0" : get(data, getFN(col)) || (config.type === "View" ? (getMTU(col).default || "") : "")
              }
            } else {
              def = getMTU(col).default || (getDefaultDataTypes(col) === "false" ? false : getDefaultDataTypes(col))
            }
          }
          tempData = {...tempData, [getPN(col)]: def}
        })
        return tempData
      }
      const getIsLoading = (cols, data, updateOrView) => {
        let tempData = {}
        cols.filter((col)=> getMTU(col)?.enabled).forEach((col) => {
          let def = '';
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? get(data, getFN(col)).map((ir)=>(getIsLoading(col.children, ir, updateOrView))) : [{...getIsLoading(col.children, {}, get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0)}]
          } else {
            def = false
          }
          tempData = {...tempData, [getPN(col)]: def}
        })
        return tempData
      }
      const getNestedSelectData = (cols, data, updateOrView) => {
        let tempData = {}
        cols.filter((col)=> getMTU(col)?.enabled && (col.type === "nestedselect" || (col.type === "repeater" && col.children.filter((c)=>(c.type === 'nestedselect')).length > 0))).forEach((col) => {
          let def = '';
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? get(data, getFN(col)).map((ir)=>(getNestedSelectData(col.children, ir, updateOrView))) : [{...getNestedSelectData(col.children, {}, get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0)}]
          } else if(col.type === "nestedselect") {
            def = { default: updateOrView ? get(data,getFN(col)) : null, tree: [] }
          }
          tempData = {...tempData, [getPN(col)]: def}
        })
        return tempData
      }
      const getDraftEditors = (cols, data, updateOrView) => {
        let tempData = {}
        cols.filter((col)=> getMTU(col)?.enabled && (col.type === "wysiwyg" || (col.type === "repeater" && col.children.filter((c)=>(c.type === 'wysiwyg')).length > 0))).forEach((col) => {
          let def = '';
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? get(data, getFN(col)).map((ir)=>(getDraftEditors(col.children, ir, updateOrView))) :[{...getDraftEditors(col.children, {}, get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0)}]
          } else {
            def = updateOrView ? EditorState.createWithContent(stateFromHTML((typeof(get(data, getFN(col))) !== 'undefined' ? get(data, getFN(col)) : ""))) : EditorState.createEmpty()
          }
          tempData = {...tempData, [getPN(col)]: def}
        })
        return tempData
      }
      const getCurrentFiles = (cols, data, updateOrView) => {
        let tempData = {}
        cols.filter((col)=> getMTU(col)?.enabled && (col.type === "file" || col.type === "dropzone" || (col.type === "repeater" && col.children.filter((c)=>(c.type === 'file' || c.type === 'dropzone')).length > 0))).forEach((col) => {
          let def = '';
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? get(data, getFN(col)).map((ir)=>(getCurrentFiles(col.children, ir, updateOrView))) :[{...getCurrentFiles(col.children, {}, get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0)}]
          } else if(updateOrView){
            def = get(data, getFN(col))
            if(col.type === "dropzone") {
              def = def.map((val)=>({ id: val.id, type: 'Permanent', name: get(val, (col.subFields?.name || "name")), size: get(val, (col.subFields?.size || "size")), path: get(val, (col.subFields?.path || "path")) }))
            }
          } else {
            def = getMTU(col).default || getDefaultDataTypes(col)
          }
          tempData = {...tempData, [getPN(col)]: def}
        })
        return tempData
      }
      const getSelects = (cols, data, to = {}, updateOrView) => {
        let tempData = {}
        cols.filter((col)=> getMTU(col)?.enabled && (col.type === "select" || col.type === "multiselect" || (col.type === "repeater" && col.children.filter((c)=>(c.type === 'select' || c.type === 'multiselect')).length > 0))).forEach((col) => {
          let def = '';
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? get(data, getFN(col)).map((ir,k)=>(getSelects(col.children, ir, to[getPN(col)][k], updateOrView))) : [{...getSelects(col.children, {}, to[getPN(col)][0], get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0)}]
          } else {
            if(updateOrView){
              let temp = ''
              if(col.pivoted){
                temp = get(data, "id");
              } else {
                temp = get(data, getFN(col));
              }
              if(col.type === "multiselect" && temp?.constructor === "temp".constructor){               
                if(temp.constructor === "temp".constructor && temp !== ""){
                  try{
                    temp = JSON.parse(temp) 
                  } catch(e){ console.log("Error Parsing JSON") }
                }
                def = temp instanceof Array ? temp.map((val)=>({ label: val, value: val })) : []
              } else {
                def = temp instanceof Array ? temp.map((val)=>(formatSelectRow(val,col))) : get(temp, col.subFields?.label || "name") ? formatSelectRow(temp,col) : (col.rootValue || col.pivoted) ? { label: (col.list?.data?.filter((v)=>(v.value === temp || parseInt(v.value) === parseInt(temp)))[0]?.label || temp), value: temp } : ''
              }
            } else {
              let temp = getMTU(col).default
              def = temp instanceof Array ? to[getPN(col)].filter((o)=>(temp.includes(o.value))) : to[getPN(col)].filter((o)=>(o.value === temp || parseInt(o.value) === parseInt(temp)))[0] || null
              def = def || getDefaultDataTypes(col)
            }
          }
          tempData = {...tempData, [getPN(col)]: def}
        })
        return tempData
      }
      const getStaticSelectOptions = (cols, data, updateOrView) => {
        let tempData = {}
        cols.filter((col)=> getMTU(col)?.enabled && (col.type === "select" || col.type === "multiselect" || col.type === "nestedselect" || (col.type === "repeater" && col.children.filter((c)=>(c.type === 'select' || c.type === 'multiselect' || c.type === "nestedselect")).length > 0))).forEach((col) => {
          let def = ''
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? get(data, getFN(col)).map((ir)=>(getStaticSelectOptions(col.children, ir, updateOrView))) :[{...getStaticSelectOptions(col.children, {}, get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0)}]
          } else {
            def = cloneDeep(col.list?.data instanceof Array ? col.list?.data : [])
            if(col.type === "multiselect" && getMTU(col).creatable && updateOrView){
              let tempData = get(data, getFN(col)) || []
              if(tempData.constructor === "temp".constructor && tempData !== ""){
                try{
                 tempData = JSON.parse(get(data, getFN(col))) 
                } catch(e){ console.log("Error Parsing JSON") }
              }
              if(tempData instanceof Array){
                tempData.forEach((c)=>{
                  if(def.filter((d)=>parseInt(d.value) === parseInt(c) || d.value === c).length === 0){
                    def.push({ label: get(c, col.subFields?.label || "name") || c, value: get(c, col.subFields?.value || "id") || c })
                  }
                })
              }

            }
          }
          tempData = {...tempData, [getPN(col)]: def}
        })
        return tempData
      }
      const getDynamicSelectOptions = async(cols, data, td = {}, ts = {}, updateOrView) => {
        let tempData = cloneDeep(td)
        await Promise.all(cols.filter((col)=> (getMTU(col)?.enabled) && ((col.type === "select" && col.child) || (col.type === "datepicker" && col.child) || (col.type === "repeater" && col.children.filter((c) => (getMTU(c)?.enabled && ((c.type === 'select' && c.child) || (c.type === 'datepicker' && c.child)))).length > 0))).map(async(col) => {
          let def = ''
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? merge(tempData[getPN(col)], await Promise.all(get(data, getFN(col)).map(async(ir,k)=>(await getDynamicSelectOptions(col.children, ir, {}, ts[getPN(col)][k], updateOrView))))) : merge(tempData[getPN(col)], [{...await getDynamicSelectOptions(col.children, {}, {}, ts[getPN(col)][0], get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0)}])
            tempData = {...tempData, [getPN(col)]: def}
          } else {
            let temp = null;
            if(updateOrView){
              temp = get(data, getFN(col))
              if(col.type === "datepicker"){
                temp = temp ? moment(temp).format("YYYY-MM-DD") : null
              } else {
                temp = temp instanceof Array ? temp.map((val)=>(formatSelectRow(val,col))) : get(temp, col.subFields?.label || "name") ? formatSelectRow(temp,col) : (col.rootValue || col.pivoted) ? temp : ''
              }
            } else {
              temp = getNE(ts, col)
            }
            const childName = col.child.name.replace(/ /g, '')
            if(temp){
              const response = col.child.sync ? col.child.fn({[col.type === "datepicker" ? "Date" : "id"]: temp.value || temp, list: col.list?.data}) : await col.child.fn({[col.type === "datepicker" ? "Date" : "id"]: temp.value || temp})
              tempData = (response && response.Status !== 'error') ? ({...tempData, [childName]: response instanceof Array ? response?.map((val)=>(col.child.exactSelectCols ? val : formatSelectRow(val,col.child))) : response.data?.map((val)=>(col.child.exactSelectCols ? val : formatSelectRow(val,col.child))) }) : ({...tempData, [childName]: []}) ;
            } else {
              tempData = {...tempData, [childName]: []}
            }
          }
        }))
        return tempData
      }
      const tempStaticOptions = getStaticSelectOptions(columns, data, updateOrView)
      const tempSelects = getSelects(columns, data, tempStaticOptions, updateOrView)
      return {  formData: getFormData(columns, data, updateOrView, tempStaticOptions), 
                draftEditors: getDraftEditors(columns, data, updateOrView), 
                currentFiles: getCurrentFiles(columns, data, updateOrView), 
                isLoading: getIsLoading(columns, data, updateOrView), 
                selects: tempSelects, 
                selectOptions: await getDynamicSelectOptions(columns, data, tempStaticOptions, tempSelects, updateOrView), 
                nestedSelects: getNestedSelectData(columns, data, updateOrView)
              }
      // eslint-disable-next-line
    }, [getMTU])

    useEffect(() => {
      if(! formLoading){
        document.getElementById("modalBody").scrollTop = 0
      }
    }, [wizardConfig.activeTab, formLoading])
    
    const getWizardConfig = (wizard) => {
      let tabs = []
      if(wizard){
        ds.filter((val)=> getMTU(val)?.enabled && (val.type !== "repeater" || !attributes[getPN(val)+"Repeater"]?.hidden)).sort((a,b)=>(a.order?.form < b.order?.form ? 1 : -1)).sort((a,b)=>(a.section?.id > b.section?.id ? 1 : -1)).forEach((val) => {
          if(val.section && val.section.label && !tabs.includes(val.section.label)){
            tabs.push(val.section.label)
          }
        })
      }
      if(tabs.length > 0){
        return { activeTab: 1, tabCount: tabs.length, tabs: tabs }
      }
      return {}
    }

    const parseFormDataForView = (stateData) => {
      if(config.type === "View"){
        let tempForm = cloneDeep(stateData.formData)
        Object.keys(tempForm).forEach((val)=>{
          if(tempForm[val] instanceof Array && tempForm[val][0] instanceof Object){
            let tempObjArr = []
            // Remove Empty Repeater Rows for view
            tempForm[val].forEach((row,k) => {
              let pass = false;
              Object.keys(row).forEach((key) => {
                if((! (row[key] === '' || row[key] === null || row[key] === false))){
                  if(Object.keys(row).length === 1){
                    tempObjArr.push(row[key])
                  }
                  pass = true;
                }
              })
              if(pass && Object.keys(row).length > 1){
                tempObjArr.push(row)
              }
            })
            tempForm[val] = tempObjArr
          }
        })
        stateData.formData = tempForm
      }
      return stateData
    }

    useEffect(() => {
        const setStates = async() => {
            setFormLoading(true)
            const stateData = parseFormDataForView(await getStateData(ds))
            setWizardConfig(getWizardConfig(config.wizard))
            setDraftEditors(stateData.draftEditors)
            setCurrentFiles(stateData.currentFiles)
            setIsLoading(stateData.isLoading)
            setSelectOptions(stateData.selectOptions)
            setSelects(stateData.selects)
            setNestedSelects(stateData.nestedSelects)
            setFormData(config.idKey ? {...stateData.formData, [config.idKey]: config.data.id} : stateData.formData)
            setSubmitDisabled(false)
            setFormLoading(false)
        }
        setStates();
        return () => {
            setFormData({})
            setDraftEditors({})
            setCurrentFiles({})
            setSelects({})
            setSelectOptions({})
            setIsLoading({})
            setWizardConfig((s) => ({...s, activeTab: 1}))
            setSubmitDisabled(true)
            setFormLoading(true)
        }
        // eslint-disable-next-line
    }, [])
    
    useEffect(() => {
      if(!formLoading){
        setAttributes(parseAttributes(formData, isLoading, selects))
      }
    }, [formData, isLoading, selects, formLoading, parseAttributes])

    useEffect(() => {
      if(config.expired){
        setSubmitDisabled(true)
        notify({ status: 'error', message: "This row has been updated elsewhere. Please Re-Open Form for latest data!" });
      }
    }, [config.expired])

    const formatBytes = (bytes, decimals = 2) => {
      if (bytes === 0) return "0 Bytes";
      const k = 1024, dm = decimals < 0 ? 0 : decimals, sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const defaultOnChange = (input) => {	
      return (e) => {
        setFormData(getUPE(formData, input, (getMTU(input).classes || "").split(" ").includes("dv") ? convertToThaana(e.target.value) : e.target.value))	
      }	
    }

    const getSelectAttr = (input) => {
      let attr = {}, onChange = '', value = ''
      if(input.type === "select"){
        value = getNE(formData, input) && getNE(formData, input) !== "" ? {label: getNE(selects,input).label, value: getNE(selects,input).value} : ""
        onChange = async(e) => { 
          // if child exists, call child function to get data for child on change of this (parent)
          if(input.child){
            const childName = input.child.name.replace(/ /g, '')
            let childInput = {};
            if(input.repName){
              childInput = ds.filter((f)=>(f.label === input.repName.replace(/([A-Z])/g, " $1").substr(1)))[0]?.children?.filter((f)=>(f.label === childName.replace(/([A-Z])/g, " $1").substr(1)))[0];
              childInput = {...childInput, repName: input.repName, repKey: input.repKey}
            } else {
              childInput = ds.filter((f)=>(f.label === childName.replace(/([A-Z])/g, " $1").substr(1) || f.labelEn === childName.replace(/([A-Z])/g, " $1").substr(1)))[0]
            }
            setIsLoading(getUPE(isLoading, childInput, true))
            const response = e?.value ? input.child.sync ? input.child.fn({id: e?.value || "", list: input.list?.data}) : await input.child.fn({id: e?.value || ""}) : [];
            if(response instanceof Array){
              setSelectOptions(getUPE(selectOptions, childInput, response?.map((val)=>(formatSelectRow(val,input.child)))))
              setIsLoading(getUPE(isLoading, childInput, false))
            } else if(! (response?.Status === "error")){
              setSelectOptions(getUPE(selectOptions, childInput, input.exactSelectCols ? response.data : response.data?.map((val)=>(formatSelectRow(val,input.child)))))
              setIsLoading(getUPE(isLoading, childInput, false))
            } else {
              notify({ status: 'error', message: "Failed to fetch data. Please refresh and try again!" });
              setSelectOptions(getUPE(selectOptions, childInput, [])); 
            }
            const def = getMTU(childInput).default || getDefaultDataTypes(childInput)
            setFormData(getUPE(getUPE(formData, input, e?.value || ""), childInput, def))
            setSelects(getUPE(getUPE(selects, input, e ? {label: e.label, value: e.value} : ""), childInput, def))
          } else {
            setFormData(getUPE(formData, input, e?.value || ""));
            setSelects(getUPE(selects, input, e ? {label: e.label, value: e.value} : ""))
          }
        }
      } else if(input.type === "multiselect") {
        value = getNE(formData, input) && getNE(formData, input) !== "" ? getNE(selects,input).map((val)=>({label: val.label, value: val.value})) : ""
        onChange = (e) => { 
          setFormData(getUPE(formData, input, e ? e.map((val)=>(val?.value)) : []))
          setSelects(getUPE(selects, input, e ? e.map((val)=>({label: val?.label, value: val?.value})) : []))
          if(getMTU(input).creatable){
            let options = cloneDeep(getNE(selectOptions, input))
            e?.forEach((c)=>{
                if(options.filter((d)=>parseInt(d.value) === parseInt(c.value) || d.value === c.value).length === 0){
                  options.push(c)
                }
            })
            setSelectOptions(getUPE(selectOptions, input, options))
          }
        }
        attr = { ...attr, isMulti: true, closeMenuOnSelect: false }
      }
      
      const loadOptions = input.list?.ovf && input.getRequest ? debounce((inputValue,callback)=>{
        let params = {SearchQuery: inputValue || ""}
        if(input.parent){
          params = { ...params, id: formData[input.parent.replace(/ /g, '')] }
        }
        input.getRequest(params).then(response=>callback(response?.data?.map((val)=>(formatSelectRow(val,input)))));
      }, 300) : null;
      return {...attr, classNamePrefix: "select2-selection", value: value, [input.list?.ovf ? 'defaultOptions' : 'options']: getNE(selectOptions, input), placeholder: (getMTU(input).classes || "").split(" ").includes("dv") ? "ނަންގަވާ..." : "Select...", loadOptions: loadOptions, onChange: onChange, isClearable: !getMTU(input).preventClear }
    }

    const getSwitchAttr = (input) => {
      const checkedIcon = <div style={{display: "flex",justifyContent: "center",alignItems: "center",height: "100%",fontSize: 12,color: "#fff",paddingRight: 2}}>{" "}{input.list ? input.list[0] || "Yes" : "Yes"}</div>
      const uncheckedIcon = <div style={{display: "flex",justifyContent: "center",alignItems: "center",height: "100%",fontSize: 12,color: "#fff",paddingRight: 2}}>{" "}{input.list ? input.list[1] || "No" : "No"}</div>
      const onChange = () => setFormData(getUPE(formData, input, !getNE(formData, input)))
      const checked = getNE(formData,input) === true;
      return { onColor: "#38a4f8", onChange: onChange, checked: checked, uncheckedIcon: uncheckedIcon, checkedIcon: checkedIcon}
    }

    const getMaskAttr = (input) => {
      const mask = getMTU(input).mask ? getMTU(input).mask : ""
      return { mask: mask, value: getNE(formData, input), onChange: defaultOnChange(input), className: (getMTU(input).classes || '')+" form-control input-color" }
    }

    const getTagsAttr = (input) => {
      const textSeparator = getMTU(input).textSeparator ? getMTU(input).textSeparator : ","
      return { separators: getMTU(input).separators || [textSeparator, "Enter", "Tab"], value: (getNE(formData, input) !== '' ? getNE(formData, input).split(textSeparator) : []), onChange: (e) => setFormData(getUPE(formData, input, e.join(textSeparator))) }
    }

    const [datepickerChanged, setDatepickerChanged] = useState({ input: null, value: null })

    useEffect(()=>{
      if(datepickerChanged.input){
        setFormData(getUPE(formData, datepickerChanged.input, datepickerChanged.value))
      }
      // eslint-disable-next-line
    }, [datepickerChanged])

    const getDatepickerAttr = (input) => {
      const options = {...getMTU(input).options}
      let onChange = async(_,e) =>  {
        if(input.child){
          const childName = input.child.name.replace(/ /g, '')
          let childInput = {};
          if(input.repName){
            childInput = ds.filter((f)=>(f.label === input.repName.replace(/([A-Z])/g, " $1").substr(1)))[0]?.children?.filter((f)=>(f.label === childName.replace(/([A-Z])/g, " $1").substr(1)))[0];
            childInput = {...childInput, repName: input.repName, repKey: input.repKey}
          } else {
            childInput = ds.filter((f)=>(f.label === childName.replace(/([A-Z])/g, " $1").substr(1)))[0]
          }
          setIsLoading(getUPE(isLoading, childInput, true))
          const response = e ? input.child.sync ? input.child.fn({Date: e || "", list: input.list?.data}) : await input.child.fn({Date: e || ""}) : [];
          if(response instanceof Array){
            setSelectOptions(getUPE(selectOptions, childInput, response?.map((val)=>(formatSelectRow(val,input.child)))))
            setIsLoading(getUPE(isLoading, childInput, false))
          } else if(! (response?.Status === "error")){
            setSelectOptions(getUPE(selectOptions, childInput, input.exactSelectCols ? response.data : response.data?.map((val)=>(formatSelectRow(val,input.child)))))
            setIsLoading(getUPE(isLoading, childInput, false))
          } else {
            notify({ status: 'error', message: "Failed to fetch data. Please refresh and try again!" });
            setSelectOptions(getUPE(selectOptions, childInput, [])); 
          }
          const def = getMTU(childInput).default || getDefaultDataTypes(childInput)
          setFormData(getUPE(getUPE(formData, input, e?.value || ""), childInput, def))
          setSelects(getUPE(getUPE(selects, input, e ? {label: e.label, value: e.value} : ""), childInput, def))
        }
        setDatepickerChanged({ input: input, value: e })
      }
      if(options.mode === "range"){
        onChange = (_,e) => e.split(" to ").length > 1 ? setDatepickerChanged({ input: input, value: e.split(" to ") }) : false
      } else if(options.mode === "multiple"){
        onChange = (_,e) => setDatepickerChanged({ input: input, value: e.split(", ") })
      }
      return { value: getNE(formData, input), onChange: onChange, options: options, className: (getMTU(input).classes || '')+" form-control d-block" }
    }

    const getNormalAttr = (input) => {
      return { type: input.type, value: getNE(formData, input), onChange: defaultOnChange(input), className: isDhivehi(getNE(formData, input)) ? "dv" : "" }
    }

    const getQuillAttr = (input) => {
      return { value: getNE(formData, input), onChange: (e)=>setFormData(getUPE(formData, input, e)), className: (getMTU(input).classes || ''), style: getMTU(input).style || {}, simpleMode: getMTU(input)?.simpleMode === true }
    }

    const getMceAttr = (input) => {
      return { value: getNE(formData, input), onChange: (e)=>setFormData(getUPE(formData, input, e)), simpleMode: getMTU(input)?.simpleMode === true, height: getMTU(input)?.height || 500 }
    }

    const getWysiwygAttr = (input) => {
      const onChange = (e) => setFormData(getUPE(formData, input, draftToHtml(e)))
      const onEditorStateChange = (e) => setDraftEditors(getUPE(draftEditors, input, e))
      return { type: input.type, editorState: getNE(draftEditors, input), onChange: onChange, onEditorStateChange: onEditorStateChange }
    }

    const nestedSelectChange = useCallback((value,input) => {
      setFormData((state) => getUPE(state, input, value))
      // eslint-disable-next-line
    }, [setFormData])

    const setNestedSelectTree = useCallback((value,input) => {
      setNestedSelects((state) => getUPE(state, input, value, "tree"))
      // eslint-disable-next-line
    }, [setNestedSelects])

    const getNestedSelectAttr = (input) => {
      let tempInput = cloneDeep(input)
      delete tempInput.list?.data
      return { list: getNE(selectOptions, input), onChange: nestedSelectChange, dynamicInput: input, activeTree: getNE(nestedSelects, input).tree, setActiveTree: setNestedSelectTree, defaultValue: getNE(nestedSelects, input).default }
    }

    const getAttr = (input) => {
      // return null
      let attr = {...(getMTU(input).attributes || {}), ...(getNE(attributes,input) || {}), className: getMTU(input).classes || ''}
      if(input.type === "nestedselect"){
        attr = {...attr, ...getNestedSelectAttr(input)}
      } else if(input.type === "select" || input.type === "multiselect"){
        attr = {...attr, ...getSelectAttr(input)}
      } else if(input.type === "switch"){
        attr = {...attr, ...getSwitchAttr(input)}
      } else if(input.type === "mask"){
        attr = {...attr, ...getMaskAttr(input)}
      } else if(input.type === "datepicker"){
        attr = {...attr, ...getDatepickerAttr(input)}
      } else if(input.type === "wysiwyg"){
        attr = {...attr, ...getWysiwygAttr(input)}
      } else if(input.type === "quill"){
        attr = {...attr, ...getQuillAttr(input)}
      } else if(input.type === "tags"){
        attr = {...attr, ...getTagsAttr(input)}
      } else if(input.type === "tinymce"){
        attr = {...attr, ...getMceAttr(input)}
      } else {
        attr = {...attr, ...getNormalAttr(input)}
      }
      return attr
    }

    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

    const uploadDzFile = async(e, input) => {
      let tempFormData = cloneDeep(formData);
      let tempCurrentFiles = cloneDeep(currentFiles);
      await Promise.all(e.map(async(file) => {
        let id = Math.random();
        file.id = id;
        file.base64 = await toBase64(file);
        getNE(tempFormData, input).push(file);
        getNE(tempCurrentFiles, input).push({ id: id, type: 'Temp', name: file.name, size: file.size, path: URL.createObjectURL(file) }) 
      }))
      setFormData(tempFormData);
      setCurrentFiles(tempCurrentFiles);
    }
    
    const removeDzFile = async(input, file, confirmed=false, e) => {
      let tempFiles = [];
      let removed = true;
      await Promise.all(getNE(currentFiles, input).map(async(f) => {
        if(f.id !== file.id){
          tempFiles.push(f)
        } else if(f.id === file.id && f.type === 'Permanent' && input.deleteFn){
          if(!confirmed){
            const el = e.target.getBoundingClientRect();
            const action = { fn: removeDzFile, params: [input,file,true] }
            toggleConfirmDialog(f.id, action, el.top+40, el.left)
            removed = false;
          } else {
            setSubmitDisabled(true)
            const response = await input.deleteFn({ id: f.id });
            handleResponse(response)
            if(! (response?.Status ==='success')){
              removed = false;
            }
            setSubmitDisabled(false)
          }
        }
      }))
      if(removed){
        setCurrentFiles(getUPE(currentFiles, input, tempFiles))
        setFormData(getUPE(formData, input, formData[getPN(input)].filter((f) => (f.id !== file.id))))
      }
    }
    
    const toggleWizardTab = (tab) => {
        if (wizardConfig.activeTab !== tab) {
            if( tab >= 1 && tab <= wizardConfig.tabCount ){
                setWizardConfig((s) => ({...s, activeTab: tab}))
            }
        } 
    }
    
    const addRepeaterRow = async(input) => {
      const initialData = await getStateData(input.children, {}, false)
      setIsLoading({...isLoading, [getPN(input)]: [...isLoading[getPN(input)], initialData.isLoading]})
      if(Object.keys(initialData.draftEditors).length > 0){
        setDraftEditors({...draftEditors, [getPN(input)]: [...draftEditors[getPN(input)], initialData.draftEditors]})
      }
      if(Object.keys(initialData.currentFiles).length > 0){
        setCurrentFiles({...currentFiles, [getPN(input)]: [...currentFiles[getPN(input)], initialData.currentFiles]})
      }
      if(Object.keys(initialData.selects).length > 0){
        setSelects({...selects, [getPN(input)]: [...selects[getPN(input)], initialData.selects]})
      }
      if(Object.keys(initialData.selectOptions).length > 0){
        setSelectOptions({...selectOptions, [getPN(input)]: [...selectOptions[getPN(input)], initialData.selectOptions]})
      }
      setFormData({...formData, [getPN(input)]: [...formData[getPN(input)], initialData.formData]})
    }

    const deleteRepeaterRowPre = async(e,input,data,key) => {
      if(input.deleteFn && data.id){
        const el = e.target.getBoundingClientRect()
        const action = { fn: deleteRepeaterRow, params: [input,key,data] }
        toggleConfirmDialog(data.id, action, el.top+40, el.left)
      } else {
        await deleteRepeaterRow(input,key,data)
      }
    }
    
    const deleteRepeaterRow = async(input,key,data) => {
      let removed = true;
      if(input.deleteFn && data.id){
        setSubmitDisabled(true)
        const response = await input.deleteFn({ id: data.id });
        handleResponse(response)
        if(! (response?.Status ==='success')){
          removed = false;
        }
        setSubmitDisabled(false)
      }
      if(removed){
        setIsLoading({...isLoading, [getPN(input)]: isLoading[getPN(input)].filter((_,k)=>(k!==key))})
        if(typeof(draftEditors[getPN(input)]) !== 'undefined'){
          setDraftEditors({...draftEditors, [getPN(input)]: draftEditors[getPN(input)].filter((_,k)=>(k!==key))})
        } else if(typeof(currentFiles[getPN(input)]) !== 'undefined'){
          setCurrentFiles({...currentFiles, [getPN(input)]: currentFiles[getPN(input)].filter((_,k)=>(k!==key))})
        } else if(typeof(selects[getPN(input)]) !== 'undefined'){
          setSelects({...selects, [getPN(input)]: selects[getPN(input)].filter((_,k)=>(k!==key))})
          setSelectOptions({...selectOptions, [getPN(input)]: selectOptions[getPN(input)].filter((_,k)=>(k!==key))})
        }
        setFormData({...formData, [getPN(input)]: formData[getPN(input)].filter((_,k)=>(k!==key))})
      }
    }   

    const submitWizardForm = async(e) => {
      e.preventDefault();
      const tab = wizardConfig?.activeTab
      if (tab === wizardConfig?.tabCount){
        onSubmit(e)
      } else {
        toggleWizardTab(tab + 1);
      }
    }
    const onSubmit = async(event) => {
      event.preventDefault();
      setSubmitDisabled(true)
      // Form Data State is looped through to remove properties from file arrays and make it just a base64 array
      let tempForm = cloneDeep(formData)
      Object.keys(tempForm).forEach((val)=>{
        // hidden, disabled & readonly will not post
        if(attributes[val]?.hidden || attributes[val]?.disabled || attributes[val]?.isDisabled || attributes[val+"Repeater"]?.hidden || attributes[val+"Repeater"]?.disabled || attributes[val+"Repeater"]?.isDisabled) {
          delete tempForm[val]
        } else if(tempForm[val] instanceof Array && tempForm[val][0] && tempForm[val][0] instanceof File){
          tempForm[val].forEach((f,k) => {
            tempForm[val][k] = f.base64
          })
        } else if(tempForm[val] instanceof Array && tempForm[val][0] instanceof Object){
          let tempObjArr = []
          // Remove Empty Repeater Rows and convert Obj to Arr if only 1 element in repeater
          tempForm[val].forEach((row,k) => {
            let pass = false;
            Object.keys(row).forEach((key) => {
              if((attributes[val][k][key]?.hidden || attributes[val][k][key]?.disabled || attributes[val][k][key]?.isDisabled)){
                delete tempForm[val][k][key]
              } else if((! (row[key] === '' || row[key] === null || row[key] === false))){
              // if(! (row[key] === '' || row[key] === null)){
                if(Object.keys(row).length === 1 && val !== "AnalysisJudges"){
                  tempObjArr.push(row[key])
                }
                pass = true;
              }
            })
            if(pass && (Object.keys(row).length > 1 || val === "AnalysisJudges")){
              tempObjArr.push(row)
            }
          })
          tempForm[val] = tempObjArr
        }
      })
      if(config.type === "Update" || config.type === "Create"){
        const response = await submitModes[config.type.toLowerCase()][config.typeKey].action(tempForm)
        handleResponse(response)
        if(response?.Status ==='success') {
          if((!pageWebsocketsEnabled) || disableSockets){
            setLastAPIActionTime(new Date())
          }
          if((submitModes[config.type.toLowerCase()][config.typeKey].closeOnSubmit || (config.type === "Create" && submitModes[config.type.toLowerCase()][config.typeKey].closeOnSubmit !== false) || response?.closeModal) && config.toggleModal) {
              config.toggleModal({});
          }
        }
        setSubmitDisabled(false)
      }
    }

    const prevBtnRef = useRef(null)
    const nextBtnRef = useRef(null)
    
    useEffect(() => {
      const handleKeyUp = (event) => {
        if(event.ctrlKey && event.altKey && event.keyCode === 39){
          if(nextBtnRef.current && window.getComputedStyle(nextBtnRef.current).display !== 'none'){
            nextBtnRef.current.click();
          }
        } else if(event.ctrlKey && event.altKey && event.keyCode === 37){
          if(prevBtnRef.current && window.getComputedStyle(prevBtnRef.current).display !== 'none'){
            prevBtnRef.current.click();
          }
        }
      }
      document.addEventListener('keyup', handleKeyUp)
      return () => {
        document.removeEventListener('keyup', handleKeyUp)
      }
      // eslint-disable-next-line
    }, [])

    const toggleConfirmDialog = (id=null, action=null, top=0,left=0) => {
      setConfirmDialog((s)=>({...s, id: id, action: action, top: top, left: left, visible: (id === null ? false : !s.visible)}))
    }

    const getDzThumbnail = (file) => {
      // if file name ends with pdf, return pdf icon else return image
      if(file.name.split('.').pop() === 'pdf'){
        return process.env.REACT_APP_API_PUBLIC_URL + "assets/pdf.png"
      } else if(file.name.split('.').pop() === 'docx' || file.name.split('.').pop() === 'doc'){
        return process.env.REACT_APP_API_PUBLIC_URL + "assets/docx.png"
      } else if(file.name.split('.').pop() === 'xlsx' || file.name.split('.').pop() === 'xls'){
        return process.env.REACT_APP_API_PUBLIC_URL + "assets/xlsx.png"
      }
      return (file.type === "Permanent" ? (process.env.REACT_APP_STORAGE_TYPE === "external" ? process.env.REACT_APP_STORAGE_URL : process.env.REACT_APP_API_PUBLIC_URL) : "")+file.path
    }

    const getFilename = (input) => {
      let name = getNE(currentFiles, input)
      if(name){
        if(name.includes('/')){
          return name.split('/').pop()
        }
        return "# | " + name
      }
      return "ފައިލް އެއް ނަންގަވާ"
    }

    const renderInputs = (data) => {
      // return data.sort((a,b)=>(a.order?.form < b.order?.form ? 1 : -1)).sort((a,b)=>(a.section?.id > b.section?.id ? 1 : -1)).map((val,key)=>(
      return cloneDeep(data).filter((v)=>(getMTU(v)?.enabled)).sort((a,b)=>(a.order?.form < b.order?.form ? 1 : -1)).sort((a,b)=>(a.section?.id > b.section?.id ? 1 : -1)).map((val,key)=>(
        <React.Fragment key={key}>
          {getMTU(val)?.enabled && (!getNE(attributes,val)?.hidden) && (val.type !== "repeater" || !attributes[getPN(val)+"Repeater"]?.hidden) && config.type !== "View" ? (
            <React.Fragment>
              {!config.wizard && ((data.filter((v)=>(getMTU(v)?.enabled))[key-1] && val.section?.id && val.section?.id !== data.filter((v)=>(getMTU(v)?.enabled))[key-1].section?.id) || (!data.filter((v)=>(getMTU(v)?.enabled))[key-1] && val.section?.id)) && !submitModes[config.type.toLowerCase()][config.typeKey].disableSections ? (
                <Col className={data.filter((v)=>(getMTU(v)?.enabled))[key-1] ? 'mt-3 mb-3' : 'mb-3'} xs={12} style={{backgroundColor:'rgba(0,0,0,0.1)', borderTopLeftRadius:"15px", borderTopRightRadius:"15px"}}><h5 className="pt-3 mb-3"><strong>{val.section?.label}</strong></h5></Col>
              ) : null}
              {(val.newLineBefore || getMTU(val).newLineBefore) ? <Col xs={12}></Col> : null}
              <Col key={key} data-order={val.order.form} 
                  xs={getMTU(val).size?.xs || getMTU(val).size?.sm || getMTU(val).size?.md || getMTU(val).size?.lg || getMTU(val).size?.xl || getMTU(val).size?.xxl || getMTU(val).size || 6}
                  sm={getMTU(val).size?.sm || getMTU(val).size?.md || getMTU(val).size?.xs || getMTU(val).size?.lg || getMTU(val).size?.xl || getMTU(val).size?.xxl || getMTU(val).size || 6}
                  md={getMTU(val).size?.md || getMTU(val).size?.lg || getMTU(val).size?.sm || getMTU(val).size?.xl || getMTU(val).size?.xs || getMTU(val).size?.xxl || getMTU(val).size || 6}
                  lg={getMTU(val).size?.lg || getMTU(val).size?.xl || getMTU(val).size?.md || getMTU(val).size?.xxl || getMTU(val).size?.sm || getMTU(val).size?.xs || getMTU(val).size || 6}
                  xl={getMTU(val).size?.xl || getMTU(val).size?.xxl || getMTU(val).size?.lg || getMTU(val).size?.md || getMTU(val).size?.sm || getMTU(val).size?.xs || getMTU(val).size || 6}
                  xxl={getMTU(val).size?.xxl || getMTU(val).size?.xl || getMTU(val).size?.lg || getMTU(val).size?.md || getMTU(val).size?.sm || getMTU(val).size?.xs || getMTU(val).size || 6}
                    >
                <FormGroup style={val.type === "checkbox" ? {cursor:'pointer'} : null} onClick={() => val.type === "checkbox" ? setFormData(getUPE(formData, val, !getNE(formData, val))) : false} className={val.type === "select" ? "selec2-container" : ""}>
                  { (!(val.repKey && val.repKey > 0) || val.repeatLabel) && (!getMTU(val).hideLabel) ? (
                    <Label for={val.label}>{getLL(val)}{getMTU(val).validations?.indexOf("required") >= 0 ? <span style={{color:"red"}}> *</span> : null} {getMTU(val).subText ? <i style={{fontSize:'0.8em', color:'#999'}}>({getMTU(val).subText})</i> : null}</Label>
                  ) : null}
                  {(val.type === "text" || val.type === "email" || val.type === "password" || val.type === "number" || val.type === "textarea" || val.type === "color" ? (
                    <Input {...getAttr(val)} />
                  ) : null )}
                  {val.type === "nestedselect" ? (
                    <NestedSelect {...getAttr(val)} />
                  ) : null}
                  {val.type === "quill" ? (
                    <QuillEditor {...getAttr(val)} />
                  ) : null}
                  {val.type === "tinymce" ? (
                    <MceEditor {...getAttr(val)} />
                  ) : null}
                  {val.type === "select" || val.type === "multiselect" ? (
                    val.list?.ovf ? <AsyncSelect {...getAttr(val)} /> : (getMTU(val).creatable ? <CreatableSelect {...getAttr(val)} /> : <Select {...getAttr(val)} components={ (getMTU(val).classes || "").split(" ").includes("dv") ? { Input: ThaanaInput } : {}} />)
                  ) : null}
                  {val.type === "switch" ? (
                    <>
                      { !(val.repKey && val.repKey > 0) || val.repeatLabel ? <br/> : null}
                      <Switch {...getAttr(val)} />
                    </>
                  ) : null}
                  {val.type === "checkbox" ? (
                    <div className="custom-control custom-checkbox" style={{zIndex:0}}>
                      <input type={val.type} className={(getMTU(val).classes || '')+" custom-control-input"} {...(getMTU(val).attributes || {})} {...(getNE(attributes, val) || {})} onChange={() => false} checked={Boolean(getNE(formData,val))} />
                      <label className="custom-control-label" onClick={() => setFormData(getUPE(formData, val, !getNE(formData, val)))} ></label>
                    </div>
                  ) : null}
                  {val.type === "radio" ? (
                    <React.Fragment>
                      {val.list?.map((radio,key)=>(
                        <div key={key} className="form-check mb-1" onClick={() => {
                            let tempForm = getUPE(formData, val, radio.value)
                            if(val.repeatRadio){
                              tempForm = {...tempForm, [val.repName]: tempForm[val.repName].map((t,k)=>(k !== val.repKey ? {...t, [getPN(val)] : "0"} : t))}
                            }
                            setFormData(tempForm);
                          }}>
                            <input className={(getMTU(val).classes || '')+" form-check-input"} type="radio" {...(getMTU(val).attributes || {})} {...(getNE(attributes, val) || {})} name={getPN(val)} onChange={() => false} value={radio.value} checked={Boolean(getNE(formData,val) === radio.value)} />
                            <label className="form-check-label">{radio.label}</label>
                        </div>
                      ))}
                    </React.Fragment>
                  ): null}
                  {val.type === "mask" ? (
                    <InputMask {...getAttr(val)} />
                  ): null}
                  {val.type === "tags" ? (
                    <div className={getMTU(val).classes}><TagsInput {...getAttr(val)} /></div>
                  ): null}
                  {val.type === "datepicker" ? (
                    <Flatpickr {...getAttr(val)} />
                  ) : null}
                  {val.type === "wysiwyg" ? (
                    <Editor {...getAttr(val)} toolbarClassName="toolbarClassName" wrapperClassName="wrapperClassName" editorClassName="editorClassName" />
                  ) : null}
                  {val.type === "file" ? (
                    <div style={{position:'relative'}}>
                      <Input type="text" className='dv' value={getFilename(val)} style={{color: getFilename(val) === "ފައިލް އެއް ނަންގަވާ" ? "rgba(0,0,0,0.5)" : ""}} onChange={(e)=>false} />
                      <Input style={{opacity:'0',top:0,position:'absolute',zIndex:999}} type={val.type} {...(getNE(attributes, val) || {})} className={(getMTU(val).classes || '')+" custom-file-input"} onChange={async(e) => {
                        setFormData(getUPE(formData, val, await toBase64(e.target.files[0]) || {}));
                        setCurrentFiles(getUPE(currentFiles, val, e.target.files[0]?.name))
                      }} />
                    </div>
                  ) : null}
                  {val.type === "dropzone" ? (
                    <React.Fragment>
                      <Dropzone onDrop={async(e) => await uploadDzFile(e, val)}>
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div className="dz-message needsclick mt-2" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="mb-3"><i className="display-4 text-muted bx bxs-cloud-upload"></i></div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div className="dropzone-previews mt-3" id="file-previews">
                        <Row>
                          {getNE(currentFiles, val)?.map((file, key) => {
                            return (
                              <Col xs={6} key={key + "-file"}>
                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={key + "-file"}>
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                      <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" alt={file?.name} src={getDzThumbnail(file)} />
                                      </Col>
                                      <Col>
                                        <Link to="#" className="text-muted font-weight-bold">{file?.name}</Link>
                                        <p className="mb-0"><strong>{formatBytes(file?.size)}</strong></p>
                                      </Col>
                                      <Col align={dir === "rtl" ? "left" : "right"}>
                                        <Button color='danger' disabled={submitDisabled} className='btn-sm waves-effect waves-light' onClick={(e) => removeDzFile(val, file, false, e)}>
                                            <i className='dripicons-cross d-block font-size-14'></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </React.Fragment>
                  ) : null}
                  {val.type === "repeater" ? (
                    <React.Fragment>
                        {getNE(formData,val).map((_,key)=>(
                          <Row key={key} className="mb-2 pt-2" style={{backgroundColor: key%2 === 0 ? 'rgba(0,0,0,0.07)' : ''}}>
                            <Col xs={12-(!getMTU(val).deleteBtnDisabled ? (getMTU(val).deleteBtnSize?.xs || getMTU(val).deleteBtnSize?.sm || getMTU(val).deleteBtnSize?.md || getMTU(val).deleteBtnSize?.lg || getMTU(val).deleteBtnSize?.xl || getMTU(val).deleteBtnSize?.xxl || getMTU(val).deleteBtnSize || 1) : 0)}
                                  sm={12-(!getMTU(val).deleteBtnDisabled ? (getMTU(val).deleteBtnSize?.sm || getMTU(val).deleteBtnSize?.md || getMTU(val).deleteBtnSize?.xs || getMTU(val).deleteBtnSize?.lg || getMTU(val).deleteBtnSize?.xl || getMTU(val).deleteBtnSize?.xxl || getMTU(val).deleteBtnSize || 1) : 0)}
                                  md={12-(!getMTU(val).deleteBtnDisabled ? (getMTU(val).deleteBtnSize?.md || getMTU(val).deleteBtnSize?.lg || getMTU(val).deleteBtnSize?.sm || getMTU(val).deleteBtnSize?.xl || getMTU(val).deleteBtnSize?.xs || getMTU(val).deleteBtnSize?.xxl || getMTU(val).deleteBtnSize || 1) : 0)}
                                  lg={12-(!getMTU(val).deleteBtnDisabled ? (getMTU(val).deleteBtnSize?.lg || getMTU(val).deleteBtnSize?.xl || getMTU(val).deleteBtnSize?.md || getMTU(val).deleteBtnSize?.xxl || getMTU(val).deleteBtnSize?.sm || getMTU(val).deleteBtnSize?.xs || getMTU(val).deleteBtnSize || 1) : 0)}
                                  xl={12-(!getMTU(val).deleteBtnDisabled ? (getMTU(val).deleteBtnSize?.xl || getMTU(val).deleteBtnSize?.xxl || getMTU(val).deleteBtnSize?.lg || getMTU(val).deleteBtnSize?.md || getMTU(val).deleteBtnSize?.sm || getMTU(val).deleteBtnSize?.xs || getMTU(val).deleteBtnSize || 1) : 0)}
                                  xxl={12-(!getMTU(val).deleteBtnDisabled ? (getMTU(val).deleteBtnSize?.xxl || getMTU(val).deleteBtnSize?.xl || getMTU(val).deleteBtnSize?.lg || getMTU(val).deleteBtnSize?.md || getMTU(val).deleteBtnSize?.sm || getMTU(val).deleteBtnSize?.xs || getMTU(val).deleteBtnSize || 1) : 0)}
                            >
                              <Row>
                                {renderInputs(val.children.map((c)=>({...c, repName: getPN(val), repKey: key, repeatLabel: getMTU(val).repeatLabel})))}
                                {val.divider ? <hr style={{width:'100%'}} /> : null}
                              </Row>
                            </Col>
                            {!getMTU(val).deleteBtnDisabled ? (
                              <Col xs={getMTU(val).deleteBtnSize?.xs || getMTU(val).deleteBtnSize?.sm || getMTU(val).deleteBtnSize?.md || getMTU(val).deleteBtnSize?.lg || getMTU(val).deleteBtnSize?.xl || getMTU(val).deleteBtnSize?.xxl || getMTU(val).deleteBtnSize || 1}
                                    sm={getMTU(val).deleteBtnSize?.sm || getMTU(val).deleteBtnSize?.md || getMTU(val).deleteBtnSize?.xs || getMTU(val).deleteBtnSize?.lg || getMTU(val).deleteBtnSize?.xl || getMTU(val).deleteBtnSize?.xxl || getMTU(val).deleteBtnSize || 1}
                                    md={getMTU(val).deleteBtnSize?.md || getMTU(val).deleteBtnSize?.lg || getMTU(val).deleteBtnSize?.sm || getMTU(val).deleteBtnSize?.xl || getMTU(val).deleteBtnSize?.xs || getMTU(val).deleteBtnSize?.xxl || getMTU(val).deleteBtnSize || 1}
                                    lg={getMTU(val).deleteBtnSize?.lg || getMTU(val).deleteBtnSize?.xl || getMTU(val).deleteBtnSize?.md || getMTU(val).deleteBtnSize?.xxl || getMTU(val).deleteBtnSize?.sm || getMTU(val).deleteBtnSize?.xs || getMTU(val).deleteBtnSize || 1}
                                    xl={getMTU(val).deleteBtnSize?.xl || getMTU(val).deleteBtnSize?.xxl || getMTU(val).deleteBtnSize?.lg || getMTU(val).deleteBtnSize?.md || getMTU(val).deleteBtnSize?.sm || getMTU(val).deleteBtnSize?.xs || getMTU(val).deleteBtnSize || 1}
                                    xxl={getMTU(val).deleteBtnSize?.xxl || getMTU(val).deleteBtnSize?.xl || getMTU(val).deleteBtnSize?.lg || getMTU(val).deleteBtnSize?.md || getMTU(val).deleteBtnSize?.sm || getMTU(val).deleteBtnSize?.xs || getMTU(val).deleteBtnSize || 1}>
                                <Button disabled={getNE(formData,val).length === 1 || submitDisabled} color='danger' className=' waves-effect waves-light' style={key === 0 || getMTU(val).repeatLabel ? {marginTop: '2em'} : null} onClick={(e) => deleteRepeaterRowPre(e,val,_,key)}>
                                  <i className='mdi mdi-trash-can d-block font-size-13'></i>
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                        ))}
                        {!getMTU(val).addBtnDisabled ? (
                          <Button color='success' className=' waves-effect waves-light' onClick={() => addRepeaterRow(val)}>
                            <i className='mdi mdi-plus d-block font-size-13'></i>
                          </Button>
                        ) : null}
                    </React.Fragment>
                  ) : null}
                </FormGroup>
              </Col>
            </React.Fragment>
          ) : null}
          {getMTU(val)?.enabled && (!getNE(attributes,val)?.hidden) && (val.type !== "repeater" || !attributes[getPN(val)+"Repeater"]?.hidden) && config.type === "View" ? (
            <React.Fragment>
              {((data.filter((v)=>(getMTU(v)?.enabled))[key-1] && val.section?.id && val.section?.id !== data.filter((v)=>(getMTU(v)?.enabled))[key-1].section?.id) || (!data.filter((v)=>(getMTU(v)?.enabled))[key-1] && val.section?.id)) ? (
                <Col className={data.filter((v)=>(getMTU(v)?.enabled))[key-1] ? 'mt-3' : ''} xs={12} style={{backgroundColor:'rgba(0,0,0,0.1)', borderTopLeftRadius:"15px", borderTopRightRadius:"15px"}}><h5 className="pt-3 mb-3"><strong>{val.section?.label}</strong></h5></Col>
              ) : null}
              {(val.newLineBefore || getMTU(val).newLineBefore) ? <Col xs={12}></Col> : null}
              <Col className={(key+1)%4===0 || (key)%4===0 ? "altColn" : ""} key={key} data-order={val.order.form} style={{borderBottom: val.type !== "repeater" ? '1px solid #f0f0f0' : ""}}
                  xs={getMTU(val).size?.xs || getMTU(val).size?.sm || getMTU(val).size?.md || getMTU(val).size?.lg || getMTU(val).size?.xl || getMTU(val).size?.xxl || getMTU(val).size || 6}
                  sm={getMTU(val).size?.sm || getMTU(val).size?.md || getMTU(val).size?.xs || getMTU(val).size?.lg || getMTU(val).size?.xl || getMTU(val).size?.xxl || getMTU(val).size || 6}
                  md={getMTU(val).size?.md || getMTU(val).size?.lg || getMTU(val).size?.sm || getMTU(val).size?.xl || getMTU(val).size?.xs || getMTU(val).size?.xxl || getMTU(val).size || 6}
                  lg={getMTU(val).size?.lg || getMTU(val).size?.xl || getMTU(val).size?.md || getMTU(val).size?.xxl || getMTU(val).size?.sm || getMTU(val).size?.xs || getMTU(val).size || 6}
                  xl={getMTU(val).size?.xl || getMTU(val).size?.xxl || getMTU(val).size?.lg || getMTU(val).size?.md || getMTU(val).size?.sm || getMTU(val).size?.xs || getMTU(val).size || 6}
                  xxl={getMTU(val).size?.xxl || getMTU(val).size?.xl || getMTU(val).size?.lg || getMTU(val).size?.md || getMTU(val).size?.sm || getMTU(val).size?.xs || getMTU(val).size || 6}
              >
                <FormGroup style={{marginTop:'0.5rem'}}>
                  { (!(val.repKey && val.repKey > 0) || val.repeatLabel) && !(getMTU(val).hideLabel) ? (
                    <Label for={val.label}><strong style={val.repKey >= 0 ? {} : {fontSize:"1.1em"}}><u>{getLL(val)} {getMTU(val).subText ? <i style={{fontSize:'0.8em', color:'#999'}}>({getMTU(val).subText})</i> : null}</u></strong></Label>
                  ) : null}
                  {val.type === "repeater" ? (
                    <React.Fragment>
                      {getNE(formData,val).length > 0 ? getNE(formData,val).map((_,key)=>(
                        <Row key={key} className="mb-2" style={{backgroundColor: key%2 === 0 && getNE(formData,val).length > 2 ? 'rgba(0,0,0,0.07)' : ''}}>
                          {renderInputs(val.children.map((c)=>({...c, repName: getPN(val), repKey: key, repeatLabel: getMTU(val).repeatLabel})))}
                          {val.divider ? <hr style={{width:'100%'}} /> : null}
                        </Row>
                      )) : <p><i>- ނެތް -</i></p>}
                    </React.Fragment>
                  ) : null}
                  {val.type === "select" ? (
                    <p className={(isDhivehi(getNE(selects,val).label)) ? "dv" : "enf"}>{getNE(selects,val).label || getNE(formData,val)}</p>
                  ) : null}
                  {val.type === "multiselect" ? (
                    <p className={(isDhivehi(getNE(selects,val))) || getMTU(val).dv ? "dv" : "enf"}>{getNE(selects,val) ? (getNE(selects,val).map((v,k)=>(v.label+(k+1 === getNE(selects,val).length ? "" : ", ")))) : getNE(formData,val) || ""}</p>
                  ) : null}
                  {val.type === "switch" || val.type === "checkbox" ? (
                    <p className={(isDhivehi(getNE(formData,val))) ? "dv" : "enf"}>{getNE(formData,val) ? "Yes" : "No"}</p>
                  ) : null}
                  {val.type === "wysiwyg" ? (
                    <Editor readOnly toolbarHidden {...getAttr(val)} toolbarClassName="toolbarClassName" wrapperClassName="wrapperClassName" editorClassName="editorClassName" />
                  ) : null}
                  {val.type === "file" ? (
                    <>
                      <br />
                      {getNE(currentFiles, val) ? (
                        <a href={(process.env.REACT_APP_STORAGE_TYPE === "external" ? process.env.REACT_APP_STORAGE_URL : process.env.REACT_APP_API_PUBLIC_URL)+getNE(currentFiles, val)} target="_blank" rel="noopener noreferrer" style={{color: "#2f329c"}}>{getFilename(val)}</a>
                      ) : <p className='dv'>-ފައިލް އެއް ނެތް-</p>}
                    </>
                  ) : null}
                  {val.type === "dropzone" ? (
                    <Row>{getNE(currentFiles, val)?.map((v,k)=>(<Col key={k} xs={4}><img style={{maxWidth:"100%"}} src={(process.env.REACT_APP_STORAGE_TYPE === "external" ? process.env.REACT_APP_STORAGE_URL : process.env.REACT_APP_API_PUBLIC_URL)+get(v, "path")} alt={get(v, "name")} /></Col>))}</Row>
                  ) : null}
                  {val.type === "quill" ? (
                    <QuillEditor value={getNE(formData, val)} readOnly={true} />
                  ) : null}
                  {val.type === "tinymce" ? (
                    <MceEditor value={getNE(formData, val)} readOnly={true} height={getMTU(val).height || 500} />
                  ) : null}
                  {val.type !== "repeater" && val.type !== "select" && val.type !== "multiselect" && val.type !== "switch" && val.type !== "checkbox" && val.type !== "wysiwyg" && val.type !== "file" && val.type !== "dropzone" && val.type !== "quill" && val.type !== "tinymce" ? (
                    <p className={(isDhivehi(getNE(formData,val))) ? "dv" : "enf"}>{getNE(formData,val)}</p>
                  ) : null}
                  
                </FormGroup>
              </Col>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ))
    }

    return (
        <div style={!config.modal ? {position: "relative", width:"100%"} : null}>
            <div id={config.modal ? "modalBody" : "formBody"} className={config.modal ? "modal-body" : "form-body"} style={{ ...(config.modal && config.wizard && { minHeight: "600px" }), ...(config.modal && config.fullscreen && { height: "85vh" }) }}>
                <div id="preloader" style={{position: 'absolute', display: formLoading ? "block" : "none"}}>
                    <div id="status" style={{display: formLoading ? "block" : "none"}}>
                    <div className="spinner-chase">
                        <div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div>
                    </div>
                    </div>
                </div>
                <Popover onClick={()=>toggleConfirmDialog()} placement="bottom" open={confirmDialog.visible} anchorReference="anchorPosition" anchorPosition={{ top: confirmDialog.top, left: confirmDialog.left }}>
                    <PopoverHeader>Sure to delete?</PopoverHeader>
                    <PopoverBody>
                        <Row>
                            <Col xs={6}>
                                <Button color="primary" outline className="waves-effect waves-light">Cancel</Button>
                            </Col>
                            <Col xs={6}>
                                <Button onClick={() => confirmDialog.action.fn ? confirmDialog.action.fn(...confirmDialog.action.params) : false} color="danger" className="waves-effect waves-light">OK</Button>
                            </Col>
                        </Row>
                    </PopoverBody>
                </Popover>
                {!formLoading ? config.wizard && config.type !== "View" ? (
                    // <div id="progrss-wizard" className="twitter-bs-wizard" style={{width:'100%'}}>
                    <div className="wizard clearfix" style={{width:'100%'}}>
                      <div className="steps clearfix">
                        <ul>
                        {wizardConfig.tabs?.map((tab,key)=>(
                            <NavItem key={key} className={classnames({ current: wizardConfig.activeTab === (key+1) })}>
                                <NavLink className={classnames({ active: wizardConfig.activeTab === (key+1) })} >
                                    <span className={dir==="rtl" ? "number mr-2" : "number ms-2"} style={{fontFamily:'Times New Roman'}}>{key+1 < 10 ? "0"+(key+1) : (key+1)}</span>
                                    {tab}
                                </NavLink>
                            </NavItem>
                        ))}
                        </ul>
                        <div id="bar">
                            <Progress color="success" striped animated value={(wizardConfig.activeTab / wizardConfig.tabCount) * 100} />
                            <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                        </div>
                        <TabContent activeTab={wizardConfig?.activeTab} className="twitter-bs-wizard-tab-content mt-4" style={{padding: '0 1em'}}>
                          {wizardConfig.tabs?.map((tab,key)=>(
                              <TabPane key={key+1} tabId={key+1}>
                                <Form id={"form-"+(key+1)} onSubmit={submitWizardForm}>
                                    <Row className="ml-4 mr-4">
                                    {renderInputs(ds.filter((val)=>(val.section?.label === tab)))}
                                    </Row>
                                </Form>
                              </TabPane>
                          ))}
                        </TabContent>
                      </div>
                    </div>
                ) : (
                    <Form id="form-main" onSubmit={onSubmit}>
                        <Row>
                            {renderInputs(ds)}
                        </Row>
                    </Form>
                ) : <div style={{minHeight:'100px'}}></div>}
            </ div>
            <div className={config.modal ? "modal-footer" : "form-footer"} style={{backgroundColor:'white'}}>
                {!formLoading && config.type !== "View" ? config.wizard ? (
                    <React.Fragment>
                        <Col>
                            <button ref={prevBtnRef} style={{display: wizardConfig?.activeTab === 1 ? "none" : "inline-block"}} type="button" onClick={() => toggleWizardTab(wizardConfig.activeTab - 1)} className="btn btn-secondary waves-effect">ފަހަތަށް</button>
                        </Col>
                        <Col align={dir === "rtl" ? "left" : "right"}>
                            <button ref={nextBtnRef} style={{display: wizardConfig?.activeTab === wizardConfig?.tabCount ? "none" : "inline-block"}} disabled={submitDisabled} form={"form-"+(wizardConfig?.activeTab)} className='btn btn-success waves-effect'>ކުރިޔަށް</button>
                            <Button style={{display: wizardConfig?.activeTab === wizardConfig?.tabCount ? "inline-block" : "none"}} disabled={submitDisabled} form={"form-"+(wizardConfig?.activeTab)} color='primary'>ސޭވް</Button>
                        </Col>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <button type="button" onClick={() => config.toggleModal({})} className="btn btn-secondary waves-effect" data-dismiss="modal">ކްލޯސް</button>
                        <Button disabled={submitDisabled} form="form-main" color='primary'>ސޭވް</Button>
                    </React.Fragment>
                ) : config.modal ? <button type="button" onClick={() => config.toggleModal({})} className="btn btn-secondary waves-effect" data-dismiss="modal">ކްލޯސް</button> : null}
            </div>
        </div>
                  
    )
}