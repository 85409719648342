import { decryptResponse } from "../../custom/helpers/HandleResponse";
import http from "../core/http";
const group = 'analysis';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const isDisplayedRequest =  async (data) => {
    return await http.post(`${group}/isDisplayed`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const findDataRequest =  async (data) => {
    return await http.post(`${group}/find`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const setDeltaRequest =  async (data) => {
    return await http.post(`${group}/setDelta`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const runAnalysisRequest =  async (data) => {
    return await http.post(`${group}/runAnalysis`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const cancelDataRequest =  async (data) => {
    return await http.post(`${group}/cancel`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const markAsNonChargeableRequest =  async (data) => {
    return await http.post(`${group}/markAsNonChargeable`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const deleteDataRequest =  async (data) => {
    return await http.post(`${group}/delete`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}

export const getCourtListRequest =  async (data) => {
	return await http.post(`${group}/getCourtList`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}
export const getAnalyzedByListRequest =  async (data) => {
	return await http.post(`${group}/getAnalyzedByList`, data).then(res => res.data).then(data=>decryptResponse(data)).catch(http.defaultCatch);
}
