import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Card, CardBody, CardTitle, Col, Container, Input, Label, Modal, Row } from "reactstrap";
import { advancedSearchRequest, findRequest, getMetaRequest, searchRequest } from "../../api/controller/GuestController";
import shunaLogo from "../../assets/images/shuna-logo.svg";
import lightLogo from "../../assets/images/shuna-logo-light.svg";
import notify from "../../custom/helpers/Notify";
import { Loader } from "../../custom/components/Loader";
import { CaseModal } from "../../custom/components/CaseModal";
import { addLtrMarkers, removeLtrMarkers } from "../../custom/helpers/LanguageHelpers";
import { PhraseModal } from "../../custom/components/PhraseModal";
import { handleResponse } from "../../custom/helpers/HandleResponse";
import Flatpickr from "react-flatpickr"
import Select from "react-select";
import moment from "moment";

const Home = () => {

    const [searchTypeInput, setSearchTypeInput] = useState('CaseFile'); // CaseFile, Phrase
    const [searchInput, setSearchInput] = useState('');

    const searchRef = useRef(null)

    const [isInitialPage, setIsInitialPage] = useState(true)
    const [searchedQuery, setSearchedQuery] = useState('');
    const [searchedType, setSearchedType] = useState('');
    const [searching, setSearching] = useState(false);
    const [results, setResults] = useState(null);
    
    const [pageSize] = useState(12)
    const [currentPage, setCurrentPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)

    const [initialTab, setInitialTab] = useState(1)
    const [loadingDocument, setLoadingDocument] = useState(false);
    const [docViewerVisible, setDocViewerVisible] = useState(false);
    const [docViewerData, setDocViewerData] = useState({});

    const [courts, setCourts] = useState([])
    const [judges, setJudges] = useState([])
    const [caseTypes, setCaseTypes] = useState([])

    const [filterVisible, setFilterVisible] = useState(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const defaultFilter = { Title: '', ClosedDate: '', CaseNumber: '', Court: '', Judges: [], CaseTypes: [], Claimant: '', Respondant: '', Query: '' }
    const [filterData, setFilterData] = useState(defaultFilter);
    const [filteredData, setFilteredData] = useState(filterData);

    useEffect(() => {
        async function fetchMeta(){
            const response = await getMetaRequest()
            if(response?.Status === 'error'){
                handleResponse(response)
            } else {
                setCourts(response.Courts)
                setJudges(response.Judges)
                setCaseTypes(response.CaseTypes)
            }
        }
        searchRef.current?.focus()
        fetchMeta()
        // eslint-disable-next-line
    }, [])

    
    const search = async(type, query) => {
        query = removeLtrMarkers(query.trim())
        if(query.replace(/[\p{Mn}\p{Me}\p{Mc}]/gu, "").length < 3){
            notify({ status: 'error', message: "މަދުވެގެން 3 އަކުރު ޖައްސަވާ" })
        } else {
            setSearching(true)
            setResults([])
            setSearchedType(type)
            setSearchedQuery(query)
            const response = await searchRequest({ Type: type, Query: query })
            if(response?.Status === 'error'){
                handleResponse(response)
            }
            // if is array
            if(Array.isArray(response)){
                setFilterData(defaultFilter)
                setFilteredData(defaultFilter)
                setResults(response)
                setIsInitialPage(false)
            } else if(response.message) {
                notify({ status: 'error', message: response.message })
            }
            setSearching(false)
        }
    }

    const openDocument = async(item,initialTab=null,clickIndex=0) => {
        setLoadingDocument(true)
        setDocViewerVisible(true)
        const response = await findRequest({ Type: searchedType, Reference: item.reference })
        if(response?.Status === 'error'){
            handleResponse(response)
        }
        setDocViewerData({...response, __clickIndex: clickIndex})
        setLoadingDocument(false)
        if(initialTab){
            setInitialTab(initialTab)
        }
    }

    useEffect(()=>{
      const currentMaxPages = Math.ceil(results?.length / pageSize) || 1
      if(currentMaxPages !== maxPage){
        setCurrentPage(1)
      }
      setMaxPage(currentMaxPages)
      // eslint-disable-next-line
    }, [results])

    const openFilter = () => {
        setFilterData(filteredData)
        setFilterVisible(true)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Loader loading={searching} />
                <Container fluid>
                    {isInitialPage ? (
                        <div align="center">
                            <img className="mt-5 mb-5 dark-logo" src={shunaLogo} alt="Logo" width="100px" />
                            <img className="mt-5 mb-5 light-logo" src={lightLogo} alt="Logo" width="100px" />
                            <div className="input-group" dir="rtl" style={{maxWidth:"600px"}}>
                                <select className="form-control dv" style={{maxWidth:"60px"}} value={searchTypeInput} onChange={(e)=>setSearchTypeInput(e.target.value)}>
                                    <option value="CaseFile">ޤަޒިއްޔާ</option>
                                    <option value="Phrase">ބަސްފޮތް</option>
                                </select>
                                <input ref={searchRef} className="form-control dv si" type="text" value={searchInput} onChange={(e)=>setSearchInput(addLtrMarkers(e.target.value))} onKeyUp={(e)=>{
                                    if(e.key === "Enter"){
                                        search(searchTypeInput, searchInput)
                                    }
                                }} placeholder="ހޯދާ..." />
                                <button className="btn btn-primary" type="button" onClick={()=>search(searchTypeInput, searchInput)}>ހޯދާ <span className="mdi mdi-magnify"></span></button>
                                {searchTypeInput === 'CaseFile' && <button className="btn btn-secondary" type="button" onClick={()=>openFilter()}><span className="mdi mdi-filter"></span></button>}
                                
                            </div>
                        </div>
                    ) : (
                        <div>
                            <Row>
                                <Card className="p-2">
                                    <div className="input-group" dir="rtl" style={{maxWidth:"600px"}}>
                                        <select className="form-control dv" style={{maxWidth:"60px"}} value={searchTypeInput} onChange={(e)=>setSearchTypeInput(e.target.value)}>
                                            <option value="CaseFile">ޤަޒިއްޔާ</option>
                                            <option value="Phrase">ބަސްފޮތް</option>
                                        </select>
                                        <input className="form-control dv si" type="text" value={searchInput} onChange={(e)=>setSearchInput(addLtrMarkers(e.target.value))} onKeyUp={(e)=>{
                                            if(e.key === "Enter"){
                                                search(searchTypeInput, searchInput)
                                            }
                                        }} placeholder="ހޯދާ..." />
                                        <button className="btn btn-primary" type="button" onClick={()=>search(searchTypeInput, searchInput)}>ހޯދާ <span className="mdi mdi-magnify"></span></button>
                                        {searchTypeInput === 'CaseFile' && <button className="btn btn-secondary" type="button" onClick={()=>openFilter()}><span className="mdi mdi-filter"></span></button>}
                                    </div>
                                    <CardTitle>
                                        <h5 className="dv mt-3">{searchedQuery ? <b className="dark-white">'{addLtrMarkers(searchedQuery)}'</b> : null} ޖުމްލަ <span>{results?.length}</span> ނަތީޖާ {JSON.stringify(filteredData) !== JSON.stringify(defaultFilter) ? (<span style={{cursor: 'pointer'}} className="mdi mdi-filter" onClick={()=>openFilter()}></span>) : ""}</h5>
                                    </CardTitle>
                                </Card>
                            </Row>

                            <Row className="mt-3 mb-3">
                                {searchedType === "CaseFile" &&
                                    results?.slice(((currentPage-1) * pageSize), (currentPage * pageSize)).map((item, key)=>(
                                        <Col key={key} xs={12} md={6}>
                                            <Card className='border border-secondary' style={{minHeight: '310px'}}>
                                                <CardBody className='pt-0 pb-0'>
                                                    <Row className="mt-2 mb-2">
                                                        <Col xs={12} md={9}>
                                                            <h3 className="csref-no size1-4">{item.case_numbers_text}</h3>
                                                        </Col>
                                                        <Col xs={12} md={3} align="left">
                                                            <button title="Open in New Tab" className="btn btn-secondary btn-sm mb-1" onClick={()=>window.open(`/case?ref=${item.reference}`, '_blank')}><span className="mdi mdi-open-in-new"></span></button>
                                                            &nbsp;
                                                            <button title="Ghaziyyaa" className="btn btn-primary btn-sm mb-1" onClick={()=>openDocument(item, 2)}><span className="mdi mdi-file-document-outline"></span></button>
                                                            &nbsp;
                                                            <button title="Details" className="btn btn-info btn-sm mb-1" onClick={()=>openDocument(item, 1)}><span className="mdi mdi-eye"></span></button>
                                                            &nbsp;
                                                        </Col>
                                                        <hr />
                                                        {item.title && <p style={{textDecoration: 'underline'}} className="etc size1-4 mb-0" onClick={(e)=>e.target.classList.remove("etc")}>{item.title}</p>}
                                                        <Col xs={10}>
                                                            {item.court?.name && <p className="size1-3 mb-0"><i className="mdi mdi-scale-balance size1-3"></i> {item.court?.name}</p>}
                                                            {item.case_types_text && <p className="etc size1-3 mb-0" onClick={(e)=>e.target.classList.remove("etc")}><i className="mdi mdi-select-group size1-3"></i> {item.case_types_text}</p>}
                                                            {item.formatted_claimant && <p className="etc size1-3 mb-0" onClick={(e)=>e.target.classList.remove("etc")}><i className="mdi mdi-account-arrow-left size1-3"></i> {item.formatted_claimant}</p>}
                                                            {item.formatted_respondant && <p className="etc size1-3 mb-0" onClick={(e)=>e.target.classList.remove("etc")}><i className="mdi mdi-account-arrow-right size1-3"></i> {item.formatted_respondant}</p>}
                                                            {item.closed_date && <p className="etc size1-3 mb-0" onClick={(e)=>e.target.classList.remove("etc")}><i className="mdi mdi-calendar-range size1-3"></i> {moment(item?.closed_date)?.format("DD MMMM YYYY")}</p>}
                                                        </Col>
                                                        <Col xs={2} align="left">
                                                            {item.statuses?.length > 0 && item.statuses.map((i,k)=><React.Fragment key={k}><span title={i.name} className={i.icon+" custom-status"} onClick={()=>openDocument(item, 1)} style={{backgroundColor:i.color, cursor:'pointer', paddingRight:'3px', paddingLeft:'3px'}}></span><br/></React.Fragment>)}
                                                        </Col>
                                                        <Col xs={12}>
                                                            {item.matches && item.matches.length > 0 ? (
                                                                <p className="size1-1 mb-0" dir="rtl" style={{lineHeight:"2em"}}><i className="mdi mdi-text-box-search-outline size1-3"></i>
                                                                    {item.matches.map((m,k)=>{
                                                                          const regex = new RegExp(`(${searchedQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
                                                                          return (
                                                                            <>
                                                                                <span className="search-match" key={k} onClick={()=>openDocument(item, 2, k)}>
                                                                                    {m.split(regex).map((part, index) => regex.test(part) ? <b key={index}><u>{part}</u></b> : part)}
                                                                                </span>
                                                                                &rlm;
                                                                            </>
                                                                          )
                                                                    })}
                                                                </p>
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))
                                }
                                {searchedType === "Phrase" &&
                                    results?.slice(((currentPage-1) * pageSize), (currentPage * pageSize)).map((item, key)=>(
                                        <Col key={key} xs={12} sm={6} md={4}>
                                            <Card className='border border-secondary' style={{minHeight: '220px'}}>
                                                <CardBody className='pt-0 pb-0'>
                                                    <Row className="mt-2 mb-2">
                                                        <Col xs={10}>
                                                            <h3 className="size1-4">{item.dhivehi_phrase}</h3>
                                                        </Col>
                                                        <Col xs={2} align="left">
                                                            <button className="btn btn-primary btn-sm mb-1" onClick={()=>openDocument(item)}><span className="mdi mdi-file-document-outline"></span></button>
                                                        </Col>
                                                        <hr />
                                                        <Col xs={12}>
                                                            {item.english_phrase && <p className="etc size1-4 mb-0" onClick={(e)=>e.target.classList.remove("etc")}><i className="mdi mdi-alpha-e size1-4"></i> {item.english_phrase}</p>}
                                                            {item.arabic_phrase && <p className="etc size1-4 mb-0" onClick={(e)=>e.target.classList.remove("etc")}><i className="mdi mdi-abjad-arabic size1-4"></i> {item.arabic_phrase}</p>}
                                                            {item.used_cases?.length > 0 && <p className="size1-4 mb-0"><i className="mdi mdi-paperclip size1-3"></i> {item.used_cases.map((c,k)=><React.Fragment key={k}><span className="csref-no">{c.number}</span>&nbsp;</React.Fragment>)}</p>}
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))
                                }
                            </Row>
                            {results?.length > pageSize && 
                                <div align="center">
                                    <ButtonGroup className="mb-5" style={{display:"flex", maxWidth: "400px"}}>
                                        <Button disabled={currentPage <= 1} onClick={()=>setCurrentPage(1)} color='primary' className='btn-md waves-effect waves-light' style={{marginLeft:'2px'}}>
                                            <i className='mdi mdi-chevron-double-left d-block font-size-16'></i>
                                        </Button>
                                        <Button disabled={currentPage <= 1} onClick={()=>setCurrentPage((c)=>--c)} color='primary' className='btn-md waves-effect waves-light' style={{marginLeft:'2px'}}>
                                            <i className='mdi mdi-chevron-left d-block font-size-16'></i>
                                        </Button>
                                        <Button color='primary' className='btn-md waves-effect waves-light' style={{marginLeft:'2px'}}>
                                        <Input style={{maxWidth:"50px"}} type="text" value={currentPage} onClick={(e)=>e.target.setSelectionRange(0, e.target.value.length)} onChange={(e)=>{
                                            if(parseInt(e.target.value) > 0 && parseInt(e.target.value) <= maxPage){
                                                e.target.blur()
                                                setCurrentPage(parseInt(e.target.value))
                                            }
                                        }} />
                                        </Button>
                                        <Button color='primary' className='btn-md waves-effect waves-light'>of</Button>
                                        <Button color='primary' className='btn-md waves-effect waves-light'>{maxPage}</Button>

                                        <Button disabled={currentPage >= maxPage} onClick={()=>setCurrentPage((c)=>++c)} color='primary' className='btn-md waves-effect waves-light' style={{marginLeft:'2px'}}>
                                            <i className='mdi mdi-chevron-right d-block font-size-16'></i>
                                        </Button>
                                        <Button disabled={currentPage >= maxPage} onClick={()=>setCurrentPage(maxPage)} color='primary' className='btn-md waves-effect waves-light' style={{marginLeft:'2px'}}>
                                            <i className='mdi mdi-chevron-double-right d-block font-size-16'></i>
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            }

                            {searchedType === "CaseFile" && <CaseModal loading={loadingDocument} visible={docViewerVisible} toggle={setDocViewerVisible} data={docViewerData} searchedQuery={searchedQuery} initialTab={initialTab} />}
                            {searchedType === "Phrase" && <PhraseModal loading={loadingDocument} visible={docViewerVisible} toggle={setDocViewerVisible} data={docViewerData} searchedQuery={searchedQuery} />}
                            
                        </div>
                    )}
                    
                            
                    <Modal size="lg" isOpen={filterVisible} toggle={()=>setFilterVisible(false)}>
                        <Loader loading={filterLoading} />
                        <div className="modal-header" style={{backgroundColor:"rgba(0,0,0,0.04)"}}>
                            <h5 className="modal-title mt-0" id="myModalLabel">ޤަޒިއްޔާ ފިލްޓާ</h5>
                            <button type="button" onClick={() => setFilterVisible(false)} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div id="modalBody" className="modal-body">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={12} className="mb-2">
                                            <Label>ޓައިޓްލް</Label>
                                            <Input type="text" value={filterData.Title} onChange={(e)=>setFilterData({...filterData, Title: e.target.value})} className="dv" />
                                        </Col>
                                        <Col xs={12} md={6} className="mb-2">
                                            <Label>ޤަޒިއްޔާ ނަންބަރު</Label>
                                            <Input type="text" value={filterData.CaseNumber} onChange={(e)=>setFilterData({...filterData, CaseNumber: e.target.value})} />
                                        </Col>
                                        <Col xs={12} md={6} className="mb-2">
                                            <Label>ނިމުނު ތާރީޙް</Label>
                                            <Flatpickr value={filterData.ClosedDate} onChange={(_,e)=>setFilterData({...filterData, ClosedDate: e.split(" to ")})} options={{ mode:'range', altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' }} className="form-control d-block" />
                                        </Col>
                                        <Col xs={12} className="mb-2 dv">
                                            <Label>ކޯޓު</Label>
                                            <Select value={courts?.filter(j=>parseInt(j.id) === parseInt(filterData.Court)).length > 0 ? {label:courts?.filter(j=>parseInt(j.id) === parseInt(filterData.Court))[0].name, value:filterData.Court} : null} 
                                                    onChange={(e)=>setFilterData({...filterData, Court: e?.value})} 
                                                    options={courts?.map(j=>({label: j.name, value: j.id}))} placeholder="ނަންގަވާ..." classNamePrefix="select2-selection" />
                                        </Col>
                                        <Col xs={12} className="mb-2 dv">
                                            <Label>ފަނޑިޔާރުންތައް</Label>
                                            <Select value={judges?.filter(j=>filterData.Judges.filter(fj => parseInt(fj) === parseInt(j.id)).length > 0).map(j=>({label:j.name, value:j.id}))} 
                                                    onChange={(e)=>setFilterData({...filterData, Judges: (e ? e.map((val)=>(val?.value)) : [])})} 
                                                    options={judges?.map(j=>({label: j.name, value: j.id}))} placeholder="ނަންގަވާ..." closeMenuOnSelect={false} isMulti={true} classNamePrefix="select2-selection" />
                                        </Col>
                                        <Col xs={12} className="mb-2 dv">
                                            <Label>ޤަޒިއްޔާގެ ބާވަތްތައް</Label>
                                            <Select value={caseTypes?.filter(j=>filterData.CaseTypes.filter(fj => parseInt(fj) === parseInt(j.id)).length > 0).map(j=>({label:j.name, value:j.id}))} 
                                                    onChange={(e)=>setFilterData({...filterData, CaseTypes: (e ? e.map((val)=>(val?.value)) : [])})} 
                                                    options={caseTypes?.map(j=>({label: j.name, value: j.id}))} placeholder="ނަންގަވާ..." closeMenuOnSelect={false} isMulti={true} classNamePrefix="select2-selection" />
                                        </Col>
                                        <Col xs={12} className="mb-2">
                                            <Label>މައްސަލަ ހުޝައެޅި ފަރާތް</Label>
                                            <Input type="text" value={filterData.Claimant} onChange={(e)=>setFilterData({...filterData, Claimant: e.target.value})} className="dv" />
                                        </Col>
                                        <Col xs={12} className="mb-2">
                                            <Label>މައްސަލަ ރައްދުވާ ފަރާތް</Label>
                                            <Input type="text" value={filterData.Respondant} onChange={(e)=>setFilterData({...filterData, Respondant: e.target.value})} className="dv" />
                                        </Col>
                                        <Col xs={12} className="mb-2">
                                            <Label>ޤަޒިއްޔާ ތެރެއިން...</Label>
                                            <Input type="text" value={filterData.Query} onChange={(e)=>setFilterData({...filterData, Query: e.target.value})} className="dv" />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="modal-footer" style={{backgroundColor:"rgba(0,0,0,0.1)"}}>
                            <button type="button" onClick={() => setFilterData(defaultFilter)} className="btn btn-secondary waves-effect">ރީސެޓް <span className="mdi mdi-refresh"></span></button>
                            <button type="button" onClick={async() => {
                                setFilterLoading(true)
                                const response = await advancedSearchRequest({ Type: 'CaseFile', ...filterData })
                                if(response?.Status === 'error'){
                                    handleResponse(response)
                                } else {
                                    setSearchedType('CaseFile')
                                    setFilteredData(filterData)
                                    setSearchInput(filterData.Query)
                                    setSearchedQuery(filterData.Query)
                                    setResults(response)
                                    setIsInitialPage(false)
                                    setFilterVisible(false)
                                }
                                setFilterLoading(false)
                            }} className="btn btn-primary waves-effect">ހޯދާ <span className="mdi mdi-magnify"></span></button>
                        </div>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default Home;

