import React from 'react';
import MceEditor from "../../custom/components/form-elements/MceEditor";
import { Row, Col, Modal, Card, CardBody } from "reactstrap";
import { Loader } from './Loader';


export const PhraseModal = ({visible, toggle, data, searchedQuery="", size="xl", loading=false}) => {

    return (
        <Modal size={size} isOpen={visible} toggle={()=>toggle(false)} keyboard={false}>
            <Loader loading={loading} />
            <div className="modal-header" style={{backgroundColor:"rgba(0,0,0,0.04)"}}>
                <h5 className="modal-title mt-0" id="myModalLabel">{data?.dhivehi_phrase || "ބަސްފޮތް"}</h5>
                <button type="button" onClick={() => toggle(false)} className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <React.Fragment>
                <div id="modalBody" className="modal-body">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xs={12} md={data?.used_cases?.length > 0 ? 6 : 12}>
                                    {data?.dhivehi_phrase && <p className="size1-3"><b>ދިވެހި ބަސް:</b> {data?.dhivehi_phrase}</p>}
                                    {data?.english_phrase && <p className="size1-3"><b>އިނގިރޭސި ބަސް:</b> {data?.english_phrase}</p>}
                                    {data?.arabic_phrase && <p className="size1-3"><b>ޢަރަބި ބަސް:</b> {data?.arabic_phrase}</p>}
                                </Col>
                                {data?.used_cases?.length > 0 && 
                                    <Col xs={12} md={data?.used_cases?.length > 0 ? 6 : 12}>
                                        <p className="size1-3 mb-2"><b>ބޭނުންކުރެވިފައިވާ ޤަޒިއްޔާތައް:</b></p>
                                        <ul>
                                            {data?.used_cases?.map((j,k)=><li key={k}><span className='csref-no'>{j.number}</span></li>)}
                                        </ul>
                                    </Col>
                                }

                                {data?.short_meaning &&
                                    <Col xs={12}>
                                        <hr />
                                        <p className="size1-3 mb-2"><b>ކުރު މާން:</b></p>
                                        <p className="size1-3 mb-2">{data?.short_meaning}</p>
                                    </Col>
                                }
                            </Row>
                        </CardBody>
                        {!loading && data?.meaning?.replace(/<[^>]*>/g, '').trim().length > 0 && <MceEditor value={data?.meaning} readOnly={true} initialSearch={searchedQuery} height={"60vh"} />}
                    </Card>
                </div>
                {/* <div className="modal-footer" style={{backgroundColor:"rgba(0,0,0,0.1)"}}>
                    <button type="button" onClick={() => toggle(false)} className="btn btn-secondary waves-effect">ކްލޯސް</button>
                </div> */}
            </React.Fragment>
        </Modal>
    )

}