import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getCourtListRequest } from "../../../api/controller/CaseFileController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";
import React from "react";

export const caseFileListState = atom({
    key: 'caseFileListState',
    default: [],
})
export const caseFileCourtState = atom({
	key: 'caseFileCourtState',
	default:{
		data:[],
		ovf:false,
	}
})
export const caseFileJudgeState = atom({
	key: 'caseFileJudgeState',
	default:{
		data:[],
		ovf:false,
	}
})
export const caseFileCaseTypeState = atom({
	key: 'caseFileCaseTypeState',
	default:{
		data:[],
		ovf:false,
	}
})
export const caseFileCaseStatusState = atom({
	key: 'caseFileCaseStatusState',
	default:{
		data:[],
		ovf:false,
	}
})
export const caseFileAffectedCaseStatusState = atom({
	key: 'caseFileAffectedCaseStatusState',
	default:{
		data:[],
		ovf:false,
	}
})
export const caseFileRelationTypeState = atom({
	key: 'caseFileRelationTypeState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "xl", wizard: true }

export const caseFileFilterCourtValueState = atom({
    key: 'caseFileFilterCourtValueState',
    default: { label: "ސުޕްރީމް ކޯޓު", value: 1 },
})

export const caseFileFilterState = selector({
    key: 'caseFileFilterState',
    get: ({ get }) => [
        {
            label: 'Court',
            displayLabel: 'ކޯޓު',
            size: 6,
            required: true,
            type: 'select',
            dv: true,
            value: get(caseFileFilterCourtValueState),
            list: get(caseFileCourtState),
        }
    ],
    set: ({ set }, e) => {
        if(e.filterLabel === 'Court'){
            set(caseFileFilterCourtValueState, e.value)
        }
    }
})

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const caseFileDataStructureState = selector({
    key: 'caseFileDataStructureState',
    get: ({ get }) => (
        [

			{ 
                label: 'ކޯޓް',
                name: 'Court',
                field: 'court',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'select',
                table: { maxWidth: 150 },
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 3 }, classes: 'dv' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(caseFileCourtState),
				getRequest: getCourtListRequest,
            },
            { 
                label: 'ނިމުނު ތާރީޙް' ,
                name: 'ClosedDate',
                field: 'closed_date',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'datepicker',
                order: { table: 1.1, form: 1.1 }, 
                table: { dateFormat: 'DD MMM YYYY', maxWidth: 150 },
                create: [{ enabled: true, size: { xs: 12, md: 6, lg: 3 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ޓައިޓްލް',
                name: 'Title',
                field: 'title',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'text',
                order: { table: 4, form: 5 }, 
                table: { minWidth: 400 },
                create: [{ enabled: true, size: { xs: 12, md: 12, lg: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ޤަޒިއްޔާ ނަމްބަރުތައް',
                name: 'CaseNumbers',
                field: 'case_numbers',
                type: 'repeater',
                section: { id: 1, label: 'ޖެނެރަލް' },
                order: { form: 5.1 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: true }], update: ['same_as:create.0'], view: ['same_as:create.0'],
                children: [
                    { 
                        label: 'ޤަޒިއްޔާ ނަމްބަރު' ,
                        name: 'Number',
                        field: 'number',
                        section: { id: 2, label: 'ތަފްޞީލު' },
                        type: 'text',
                        order: { form: 1 }, 
                        create: [{ enabled: true, size: { xs: 12, md: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                    },
                    { 
                        label: 'ހުޝައެޅި ތާރީޙް' ,
                        name: 'SubmissionDate',
                        field: 'submission_date',
                        section: { id: 2, label: 'ތަފްޞީލު' },
                        type: 'datepicker',
                        order: { form: 2 }, 
                        create: [{ enabled: true, size: { xs: 12, md: 4 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                    },
                    { 
                        label: 'ރަޖިސްޓާކުރި ތާރީޙް' ,
                        name: 'RegistrationDate',
                        field: 'registration_date',
                        section: { id: 2, label: 'ތަފްޞީލު' },
                        type: 'datepicker',
                        order: { form: 3 }, 
                        create: [{ enabled: true, size: { xs: 12, md: 4 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                    },
					
                ]
            },
			{ 
                label: 'މައްސަލާއިގެ ބާވަތްތައް',
                name: 'CaseTypes',
                field: 'case_types',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'multiselect',
                order: { form: 5.5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv', dv: true }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                list : get(caseFileCaseTypeState),
            },
			{ 
                label: 'މައްސަލަ ހުޝައެޅި ފަރާތް',
                name: 'Claimant',
                field: 'claimant',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'tags',
                order: { form: 6 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, textSeparator:'|', classes: 'dv' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'މައްސަލަ ރައްދުވާ ފަރާތް',
                name: 'Respondant',
                field: 'respondant',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'tags',
                order: { form: 7 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, textSeparator:'|', classes: 'dv' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ތަދައްޙުލްވި ފަރާތް',
                name: 'Interpleader',
                field: 'interpleader',
                section: { id: 1, label: 'ޖެނެރަލް' },
                type: 'tags',
                order: { form: 7.1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, textSeparator:'|', classes: 'dv' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ފަނޑިޔާރުންގެ މަޖިލީސް',
                name: 'JudgesList',
                field: 'judges',
                section: { id: 2, label: 'ތަފްޞީލު' },
                type: 'multiselect',
                order: { form: 9 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv zIndex99999', dv: true }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                list : get(caseFileJudgeState),
            },
			{ 
                label: 'ކީވޯޑްތައް',
                name: 'Keywords',
                field: 'keywords',
                section: { id: 2, label: 'ތަފްޞީލު' },
                type: 'text',
                order: { form: 12 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
            { 
                label: 'ސްޓޭޓަސްތައް',
                name: 'CaseStatuses',
                field: 'statuses',
                section: { id: 2, label: 'ތަފްޞީލު' },
                type: 'multiselect',
                order: { form: 12.1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv zIndex999' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                list: get(caseFileCaseStatusState),
            },
			{ 
                label: 'ސްޓޭޓަސްގެ ތަފްޞީލު',
                name: 'StatusDetails',
                field: 'status_details',
                section: { id: 2, label: 'ތަފްޞީލު' },
                type: 'tinymce',
                order: { form: 12.2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, simpleMode: true, height: 300 }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 }, simpleMode: true, height: 300, validations: 'hidden_if:StatusDetails,null' }], 
            },
			{ 
                label: 'ޕީޑީއެފް ފައިލް',
                name: 'PdfFile',
                field: 'pdf_file',
                section: { id: 2, label: 'ތަފްޞީލު' },
                type: 'file',
                order: { form: 13 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ޕަބްލިޝް ކުރެވިފައި؟',
                name: 'Published',
                field: 'published',
                section: { id: 2, label: 'ތަފްޞީލު' },
                type: 'switch',
                order: { form: 14 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'އަސަރުކޮށްފައިވާ ޤަޒިއްޔާތައް',
                name: 'RelatedCases',
                field: 'related_cases',
                type: 'repeater',
                section: { id: 3, label: 'އަސަރުކުރި ޤަޒިއްޔާތައް' },
                order: { form: 15 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: true }], update: ['same_as:create.0'], view: ['same_as:create.0'],
                children: [
                    { 
                        label: 'އަސަރުކުރިގޮތުގެ ބާވަތް',
                        name: 'RelationType',
                        field: 'type',
                        section: { id: 2, label: 'ތަފްޞީލު' },
                        type: 'select',
                        order: { form: 1 }, 
                        create: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }, classes: 'dv zIndex999' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                        list: get(caseFileRelationTypeState),
                    },
                    { 
                        label: 'ޤަޒިއްޔާ ނަމްބަރު' ,
                        name: 'Number',
                        field: 'number',
                        section: { id: 2, label: 'ތަފްޞީލު' },
                        type: 'text',
                        order: { form: 2 }, 
                        create: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                    },
                    { 
                        label: 'ސްޓޭޓަސް',
                        name: 'Status',
                        field: 'status',
                        section: { id: 2, label: 'ތަފްޞީލު' },
                        type: 'select',
                        order: { form: 1 }, 
                        create: [{ enabled: true, size: { xs: 12, md: 6, lg: 4 }, classes: 'dv zIndex999' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                        list: get(caseFileAffectedCaseStatusState),
                    },
                    { 
                        label: 'ތަފްޞީލު',
                        name: 'Description',
                        field: 'description',
                        section: { id: 2, label: 'ތަފްޞީލު' },
                        type: 'tinymce',
                        order: { form: 3 }, 
                        create: [{ enabled: true, size: { xs: 12, md: 12 }, simpleMode: true, height: 300 }], update: ['same_as:create.0'], view: [{ enabled: true, size: { xs: 12, md: 12 }, simpleMode: true, height: 300, validations: 'hidden_if:Description,null' }], 
                    },
					
                ]
            },
			{ 
                label: 'ކޮންޓެންޓް',
                name: 'CaseContent',
                field: 'case_content',
                section: { id: 4, label: 'ކޮންޓެންޓް' },
                type: 'tinymce',
                order: { form: 16 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, height: '60vh'}], update: ['same_as:create.0'], view: [{ enabled: false }], 
            },


            
			{ 
                label: 'ކޭސް ނަމްބަރު',
                field: 'case_numbers_text',
                type: 'text',
                order: { table: 3 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ކޭސްގެ ބާވަތް',
                field: 'case_types_text',
                type: 'text',
                order: { table: 6 }, 
                create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ކޭސް ސްޓޭޓަސް',
                field: 'statuses',
                type: 'text',
                order: { table: 7 }, 
                table: {
                    customRender: (data) => data.map((i,k)=><React.Fragment key={k}><span title={i.name} className={i.icon+" custom-status"} style={{backgroundColor:i.color}}></span>&nbsp;</React.Fragment>),
                    maxWidth: 200,
                },
                create: [{ enabled: false }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
